import _ from 'lodash';
import qs from 'qs';
import { stringToBoolean } from 'deep-cuts';
import useAPI from '../core/hooks/useApi';
import api from './api';

import { AttachmentUploadDownload } from '../state/attachment';
import { useFileUploadProgress } from '../components/FileUpload';
import { appendedFileName } from '../utils/file';
import { getUrlPath } from '../utils/string';
import { useNotifications } from '../core/hooks/notifications';
import { useRFPQuery } from '../containers/RFP/hooks/query';
import { isDevelopment } from '../utils/enviroment';

export const useGetAttachmentsByOptInId = optInId =>
  useAPI(`/api/attachments?${qs.stringify({ optInId })}`);

export const useAttachmentUploadFiles = (optInId, { updateAttachmentUploadedFile }) => {
  const { token } = useRFPQuery();
  const [progressHandler, successHandler] = useFileUploadProgress();
  const [sendNotification] = useNotifications();
  return async (id, acceptedFiles, appendToFilename = '') => {
    const fileForm = new FormData();

    // NOTE - We only accept one file at the moment.
    const file = acceptedFiles[0];
    fileForm.append('files', file, appendedFileName(file.name, appendToFilename));

    // NOTE - We use this to capture metadata for file auditing.
    const query = qs.stringify({
      optInId,
      token,
    });

    /**
     * @typedef S3FileResult {{
     *   fileKey: string,
     *   fileLink: string,
     *   s3FileLink: string,
     *   fileName: string,
     * }}
     */
    try {
      // Update name but disable download until url is obtained
      updateAttachmentUploadedFile(id, { filename: '• • •', disabled: true });

      const { data: attachment } = await api.put(`/attachments/${id}/files?${query}`, fileForm, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: _.partial(progressHandler, file),
      });

      // Update with name, url and enable attachment download
      updateAttachmentUploadedFile(id, {
        filename:
          attachment.uploadAttachment.value && getUrlPath(attachment.uploadAttachment.value),
        url: attachment.uploadAttachment.value,
        disabled: false,
      });

      // NOTE - Just call when the data is retrieved
      successHandler(file);

      return attachment;
    } catch (err) {
      const message =
        err.response != null && err.response.data
          ? err.response.data.message || err.toString()
          : err.toString();
      sendNotification('danger', {
        title: 'File Upload failed',
        message: `${file.name} failed to upload, reason: ${message}`,
      });
      return null;
    }
  };
};

export const useAttachmentDownloadFiles = optInId => {
  const { token } = useRFPQuery();
  return async attachment => {
    const id = _.get(attachment, 'id');
    const uploadDownload = _.get(attachment, 'uploadDownload');

    if (
      uploadDownload.value === AttachmentUploadDownload.UPLOAD ||
      stringToBoolean(process.env.REACT_APP_SMART_DOWNLOAD_LINKS)
    ) {
      const getDownloadUrlAndFilename = async () => {
        if (uploadDownload.value === AttachmentUploadDownload.UPLOAD) {
          const downloadUrl = _.get(attachment, ['attachment', 'value', 'url']);
          const filename = getUrlPath(downloadUrl);
          return {
            downloadUrl,
            filename,
          };
        } else {
          const { data } = await api.get(
            `/attachments/${id}/read-only-files?${qs.stringify({ optInId, token })}`,
            {
              responseType: 'arraybuffer',
            },
          );
          const filename = _.get(attachment, ['attachment', 'value', 'filename']);
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          return {
            downloadUrl,
            filename,
          };
        }
      };

      const { downloadUrl, filename } = await getDownloadUrlAndFilename();

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      // TODO - We need to clean this up to include loading indicators later, thus why we have the environment variable.
      const url = `${
        isDevelopment() ? 'http://localhost:8000' : window.location.origin
      }/api/attachments/${id}/read-only-files?${qs.stringify({ optInId, token })}`;
      window.open(url, '_blank');
    }
  };
};
