import _ from 'lodash';

export const PROPERTY_NAME_FIELD = 'propertyNameObsf';
export const RELATED_SITE_RFP_FIELD = 'relatedRfpSite';
export const RELATED_SCENARIO_FIELD = 'relatedScenario';
export const ENERGY_TECHNOLOGY_FIELD = 'energyTechnology';
export const RELATED_CUSTOMER_RFP_FIELD = 'relatedCustomerRfp';

export const getPropertyName = record =>
  _.get(record, `${PROPERTY_NAME_FIELD}.value`, _.get(record, 'propertyName.value'));
