import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { VerticallyHorizontallyCenteredCell } from '../../../../components/common/TableLayout';
import { BoldText } from '../../../../components/common/Typography';
import { alwaysParentheses } from '../../../../utils/string';

export function HeaderRenderer({ cell }) {
  const { value, customHeader, units } = cell;
  return (
    <VerticallyHorizontallyCenteredCell>
      <BoldText>
        <Row>
          <Col xs={12}>{customHeader || value}</Col>
          <Col xs={12}>{units && alwaysParentheses(units)}</Col>
        </Row>
      </BoldText>
    </VerticallyHorizontallyCenteredCell>
  );
}

HeaderRenderer.propTypes = {
  cell: PropTypes.object,
};

HeaderRenderer.defaultProps = {
  cell: null,
};

export default HeaderRenderer;
