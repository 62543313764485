import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, Container, Col, Row, Button } from 'react-bootstrap';
import styled from 'styled-components';
import ExpandCollapse from '../../../components/common/ExpandCollapse';
import ResizingTextArea from '../../../components/common/ResizingTextArea';
import FileDrop from './FileDrop';
import { useRemoveFiles, useUploadFiles } from '../../../services/bfQuestion';
import { useNotifications } from '../../../core/hooks/notifications';
import { useBBEContext } from '../../../core/store';
import { TOGGLE_EXPAND_COLLAPSE } from '../../../core/actions';
import { expandCollapseByTitleSelector } from '../../../core/selectors';
import { ReadOnlyContainer } from '../../../components/common/Interaction';
import { isDevelopment } from '../../../utils/enviroment';

const NoPadContainer = styled(Container)`
  padding: 8px 0px 8px 0px;
`;

const NoPadButton = styled(Button)`
  padding: 0;
`;

function downloadBBEFile(bfQuestion) {
  window.open(
    `${isDevelopment() ? 'http://localhost:8000' : ''}/api/bf-questions/${_.get(
      bfQuestion,
      'id',
    )}/read-only-files`,
  );
}

function FormLabel({ bfQuestion }) {
  const { filename } = _.get(bfQuestion, 'bbeFile.value', {});
  const contents = _.chain([
    <span key="0">{_.get(bfQuestion, 'question.value')}</span>,
    filename && (
      <span key="1">
        {' '}
        (
        <NoPadButton variant="link" onClick={_.partial(downloadBBEFile, bfQuestion)}>
          {filename}
        </NoPadButton>
        )
      </span>
    ),
  ])
    .filter()
    .value();
  return <Form.Label>{contents}</Form.Label>;
}

export function ClarificationQuestions({
  bfQuestions,
  locked,
  onChangeBfQuestion,
  onChangeBfQuestionAttachments,
}) {
  const uploadFile = useUploadFiles();
  const removeFile = useRemoveFiles();
  const [sendNotification] = useNotifications();

  const handleUploadFile = async (bfQuestion, { files }) => {
    const data = await uploadFile(bfQuestion.id, files);
    if (data != null) {
      onChangeBfQuestionAttachments(bfQuestion.id, data.files);
    }
  };

  const handleDeleteFile = async (bfQuestion, { fileName }) => {
    await removeFile(bfQuestion.id, fileName);
    const attachments = _.get(bfQuestion, 'answer.attachments', []);
    onChangeBfQuestionAttachments(
      bfQuestion.id,
      attachments.filter(({ fileName: attachmentFileName }) => attachmentFileName !== fileName),
    );
    sendNotification('warning', {
      title: 'File Removed',
      message: `File removed for clarification question: ${fileName}`,
    });
  };

  const { dispatch, state } = useBBEContext();
  const title = 'Clarification Questions';

  return (
    <ExpandCollapse
      title={title}
      openByDefault={expandCollapseByTitleSelector(state, title)}
      onToggle={() =>
        dispatch({
          type: TOGGLE_EXPAND_COLLAPSE,
          payload: title,
        })
      }
    >
      <ReadOnlyContainer readOnly={locked}>
        {_.map(bfQuestions, bfQuestion => {
          const debouncedOnChange = _.debounce(
            value =>
              onChangeBfQuestion({
                ...bfQuestion,
                answer: {
                  ...bfQuestion.answer,
                  value,
                },
              }),
            1500,
          );
          return (
            <Form.Group key={_.get(bfQuestion, 'id')}>
              <NoPadContainer fluid>
                <Row>
                  <Col xs={9}>
                    <FormLabel bfQuestion={bfQuestion} />
                  </Col>
                  <Col xs={3}>
                    <FileDrop
                      files={_.get(bfQuestion, 'answer.attachments', [])}
                      onDrop={({ files }) => handleUploadFile(bfQuestion, { files })}
                      onRemove={({ fileName }) => handleDeleteFile(bfQuestion, { fileName })}
                    />
                  </Col>
                </Row>
              </NoPadContainer>
              <ResizingTextArea
                rows={1}
                defaultValue={_.get(bfQuestion, 'answer.value')}
                onChange={e => debouncedOnChange(_.get(e, 'target.value'))}
              />
            </Form.Group>
          );
        })}
      </ReadOnlyContainer>
    </ExpandCollapse>
  );
}

ClarificationQuestions.propTypes = {
  bfQuestions: PropTypes.array,
  locked: PropTypes.bool,
  onChangeBfQuestion: PropTypes.func.isRequired,
  onChangeBfQuestionAttachments: PropTypes.func.isRequired,
};

ClarificationQuestions.defaultProps = {
  bfQuestions: null,
  locked: false,
};

export default ClarificationQuestions;
