import _ from 'lodash';
import qs from 'qs';
import { stringToBoolean } from 'deep-cuts';
import { useQuery } from '../../../core/hooks/url';

export const mergeQueryWithLocation = query => {
  const { origin, pathname, search } = window.location;
  const parsedSearch = qs.parse((search || '').replace(/^\?/g, ''));
  return `${origin}${pathname}?${qs.stringify({
    ...parsedSearch,
    ...query,
  })}`;
};

export const useRFPQuery = () => {
  const query = _.mapValues(useQuery(), (v, k) => {
    switch (k) {
      case 'overrideLock':
        return stringToBoolean(v);
      default:
        return v;
    }
  });

  return query;
};
