import { useGetAttachmentsByOptInId } from './attachment';
import { useGetBfQuestionsByOptInId } from './bfQuestion';
import { useGetConfirmationsByOptInId } from './confirmation';
import { useGetQuestionsByOptInId } from './question';
import { useGetRfpQuestions } from './rfpQuestion';
import { useGetSitesByOptInId } from './site';
import { useGetScenariosByOptInId } from './scenario';
import { useGetSiteScenariosByOptInId } from './siteScenario';
import { anyTrue } from '../utils/api';

export const useGetDefaultsByOptInId = (optInId, userId) => {
  const { error: errorAttachments, data: defaultAttachments, isLoading: isLoadingAttachments } =
    useGetAttachmentsByOptInId(optInId) || {};
  const { error: errorBfQuestions, data: defaultBfQuestions, isLoading: isLoadingBfQuestions } =
    useGetBfQuestionsByOptInId(optInId) || {};
  const {
    error: errorConfirmations,
    data: defaultConfirmations,
    isLoading: isLoadingConfirmations,
  } = useGetConfirmationsByOptInId(optInId) || {};
  const { error: errorQuestions, data: defaultQuestions, isLoading: isLoadingQuestions } =
    useGetQuestionsByOptInId(optInId) || {};
  const { error: errorRfpQuestions, data: defaultRfpQuestions, isLoading: isLoadingRfpQuestions } =
    useGetRfpQuestions(optInId, userId) || {};
  const { error: errorSites, data: defaultSites, isLoading: isLoadingSites } =
    useGetSitesByOptInId(optInId) || {};
  const { error: errorScenarios, data: defaultScenarios, isLoading: isLoadingScenarios } =
    useGetScenariosByOptInId(optInId) || {};
  const {
    error: errorSiteScenarios,
    data: defaultSiteScenarios,
    isLoading: isLoadingSiteScenarios,
  } = useGetSiteScenariosByOptInId(optInId) || {};
  return {
    defaultAttachments,
    defaultBfQuestions,
    defaultConfirmations,
    defaultQuestions,
    defaultRfpQuestions,
    defaultSites,
    defaultScenarios,
    defaultSiteScenarios,
    isLoading: anyTrue(
      isLoadingAttachments,
      isLoadingBfQuestions,
      isLoadingConfirmations,
      isLoadingQuestions,
      isLoadingRfpQuestions,
      isLoadingSites,
      isLoadingScenarios,
      isLoadingSiteScenarios,
    ),
    errors: [
      { request: 'Attachments', error: errorAttachments },
      { request: 'BfQuestions', error: errorBfQuestions },
      { request: 'Confirmations', error: errorConfirmations },
      { request: 'Questions', error: errorQuestions },
      { request: 'RfpQuestions', error: errorRfpQuestions },
      { request: 'Sites', error: errorSites },
      { request: 'Scenarios', error: errorScenarios },
      { request: 'SiteScenarios', error: errorSiteScenarios },
    ].filter(({ error }) => error != null),
  };
};
