import _ from 'lodash';
import { useBBEContext } from '../store';
import { ANY_CHANGE_MADE_SINCE_SAVE } from '../actions';

export const useAnyChangesSinceSave = () => {
  const { state, dispatch } = useBBEContext();
  const anyChangesMadeSinceSave = _.get(state, 'anyChanges');
  const onChangesMade = () => dispatch({ type: ANY_CHANGE_MADE_SINCE_SAVE });
  return [anyChangesMadeSinceSave, onChangesMade];
};
