import _ from 'lodash';
import { useContext } from 'react';
import moment from 'moment';
import {
  CascadingArrayDesignContext,
  CascadingStorageDesignContext,
  CascadingProjectScheduleContext,
} from '../components/CascadingSiteProvider';
import { useBBEDispatch } from '../../../core/store';
import {
  CLEAR_ANY_CHANGE_MADE_SINCE_SAVE,
  UPDATE_RECENTLY_SAVED_OPT_IN,
} from '../../../core/actions';
import { useDeleteOptInById, useUpdateOptInById } from '../../../services/optIn';
import { useNotifications } from '../../../core/hooks/notifications';
import { useLoadingContext } from '../../../components/Loader';
import { SAVED, SUBMITTED } from '../constants';
import { containsInvalidOrUnfilledValues } from '../components/helpers/errors';
import { createIgnoreForSubmissionPredicate } from '../components/helpers/field';
import { customFieldsFromScenarios } from '../components/helpers/scenario';
import { toCSV } from '../components/transformers/rfp';

const cascadingSiteFactory = ({ context }) => (sites, customize) => {
  const {
    sites: sitesFromProvider,
    customizeByScenario,
    setDefaultSites,
    setSiteOnCellChange,
    setFilesForUpload,
    setCustomizeByScenario,
  } = useContext(context);

  // NOTE - We only ever set the sites we are given, once
  setDefaultSites(sites, !!customize);

  // NOTE - These arrays are getting long, we should make these a destructured object in the future.
  return [
    sitesFromProvider,
    customizeByScenario,
    setSiteOnCellChange,
    setCustomizeByScenario,
    setFilesForUpload,
  ];
};

export const useArrayDesignDetails = cascadingSiteFactory({ context: CascadingArrayDesignContext });

export const useStorageDesignDetails = cascadingSiteFactory({
  context: CascadingStorageDesignContext,
});

export const useProjectSchedule = cascadingSiteFactory({
  context: CascadingProjectScheduleContext,
});

export function useSaveSubmit(optInId, data) {
  const dispatch = useBBEDispatch();
  const ignoreForSubmissionPredicate = createIgnoreForSubmissionPredicate({
    fields: data.fields,
    customFields: customFieldsFromScenarios(data.scenarios),
  });
  const [sendNotification, sendConfirmation] = useNotifications();
  const { startLoading, stopLoading } = useLoadingContext();
  const onSave = useUpdateOptInById(optInId, { operation: SAVED }, (data, notifyUser = false) => {
    dispatch({
      type: UPDATE_RECENTLY_SAVED_OPT_IN,
      payload: _.get(data, 'saved'),
    });
    // save is either done in background or by the user
    // even if saved in background, stopLoading has no negative effect
    stopLoading();
    if (notifyUser) {
      sendNotification('success', {
        title: 'Save Successful',
        message: 'Your bids, confirmations, and answers have been saved.',
      });
    }
  });
  const onSubmit = useUpdateOptInById(optInId, { operation: SUBMITTED }, data => {
    dispatch({
      type: UPDATE_RECENTLY_SAVED_OPT_IN,
      payload: _.get(data, 'saved'),
    });
    stopLoading();
    sendNotification('success', {
      title: 'Submitted',
      message: 'Your bids, confirmations, and answers have been submitted to Black Bear Energy.',
    });
  });
  const onDelete = useDeleteOptInById(optInId, () => {
    dispatch({ type: CLEAR_ANY_CHANGE_MADE_SINCE_SAVE });
    sendNotification('success', {
      title: 'Cleared',
      message:
        'The the saved record has been cleared, please refresh the page to see the clean form.',
    });
  });

  const onSubmitWithConfirmationAndLoading = () => {
    const loadAndSubmit = () => {
      startLoading();
      onSubmit(data);
    };
    if (containsInvalidOrUnfilledValues(data, ignoreForSubmissionPredicate)) {
      sendConfirmation('warning', {
        title: 'Unfilled/invalid values found',
        message: [
          'Are you sure you want to submit with unfilled/invalid values?',
          "Note: If you would like to opt-out of a bid, please click the 'No Bid' button\" on the relevant scenarios",
        ].join('\n\n'),
        onOk: loadAndSubmit,
      });
    } else {
      loadAndSubmit();
    }
  };

  const onSaveWithLoading = () => {
    startLoading();
    onSave(data);
  };

  return [onSaveWithLoading, onSubmitWithConfirmationAndLoading, onDelete];
}

export function useExportToCSV({
  fields,
  siteRfps,
  siteScenarios,
  scenarios,
  confirmations,
  questions,
  bfQuestions,
}) {
  return () => {
    const data = toCSV({
      fields,
      siteRfps,
      siteScenarios,
      scenarios,
      confirmations,
      questions,
      bfQuestions,
    });
    const fileName = `Black Bear Energy - Bid ${moment().format('YYYYMMDD')}.csv`;
    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
}
