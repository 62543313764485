import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import FileDrop from '../FileDrop';

const PADDING_OFFSET = 29;
const PADDING_OFFSET_WITH_TITLE = 4;

const OverlayContainer = styled.div`
  position: absolute;
  right: ${props => props.right}px;
  top: ${props => props.top}px;
  width: ${props => props.width}px;
`;

const FileDropContainer = styled.div`
  height: ${props => props.height}px;
  padding-top: ${props => props.paddingTop}px;
`;

const getRight = ({ hideBid, columnWidths }) =>
  hideBid ? 0 : _.get(columnWidths, [_.size(columnWidths) - 1], 0);
const getWidth = ({ hideBid, columnWidths }) =>
  _.get(columnWidths, [_.size(columnWidths) - (hideBid ? 1 : 2)], 0);
const hasBid = site => _.get(site, 'bid.value', false); // eslint-disable-line no-unused-vars

export function CascadingFileActionsOverlay({
  entities,
  columnWidths,
  rowHeights,
  hasTitle,
  hideBid,
  disabled = false,
  disabledCb,
  fileProperty,
  onDrop,
  onRemove,
  showAttachmentsList = true,
}) {
  const top = _.get(rowHeights, [0], 0);
  const right = getRight({
    hideBid,
    columnWidths,
  });
  const width = getWidth({
    hideBid,
    columnWidths,
  });
  return (
    <OverlayContainer top={top} right={right} width={width}>
      {_.map(entities, (entity, idx) => {
        const { id } = entity;
        const height = _.get(rowHeights, [idx + 1], 0);
        const files = _.get(entity, [fileProperty, 'value']);
        return (
          <FileDropContainer
            key={id}
            height={height}
            paddingTop={height - (hasTitle ? PADDING_OFFSET_WITH_TITLE : PADDING_OFFSET)}
          >
            <FileDrop
              disabled={disabledCb ? disabledCb(entity) : disabled}
              files={_.isArray(files) ? files : []}
              onDrop={(...args) => onDrop({ id }, ...args)}
              onRemove={(...args) => onRemove({ id }, ...args)}
              showAttachmentsList={showAttachmentsList}
            />
          </FileDropContainer>
        );
      })}
    </OverlayContainer>
  );
}

export default CascadingFileActionsOverlay;
