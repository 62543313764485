import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Accordion, Card } from './BootstrapOverrides';

export function ExpandCollapse({ children, title, openByDefault, errorCount, onToggle }) {
  return (
    <Accordion defaultActiveKey={openByDefault ? '1' : undefined}>
      <Card>
        <Card.Header title={title} errorCount={errorCount} eventKey="1" onToggle={onToggle} />
        <Accordion.Collapse eventKey="1">
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

ExpandCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  openByDefault: PropTypes.bool,
  errorCount: PropTypes.number,
  onToggle: PropTypes.func,
};

ExpandCollapse.defaultProps = {
  errorCount: 0,
  openByDefault: false,
  onToggle: _.noop,
};

export default ExpandCollapse;
