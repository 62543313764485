import _ from 'lodash';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import {
  fieldsToHeader,
  fieldsAndRecordToRow,
  matchHeaderToColumns,
  disableRowsOnPredicate,
} from './common';
import { assignGivenAndEditableToRow } from './editing';
import { getPropertyName, PROPERTY_NAME_FIELD } from './specialFields';
import BidRenderer from '../renderers/BidRenderer';
import HeaderRenderer from '../renderers/HeaderRenderer';
import EmptyRenderer from '../renderers/EmptyRenderer';
import {
  PROPERTY_NAME_COLUMN_WIDTH,
  BID_COLUMN_WIDTH,
  DATA_COLUMN_WIDTH,
  PROPERTY_NAME_COLUMN_FIELD_NAME,
  BID_COLUMN_FIELD_NAME,
  ACTIONS_COLUMN_FIELD_NAME,
} from './siteScenario';
import DefaultRenderer from '../renderers/DefaultRenderer';
import { memoize } from '../../../../utils/cache';
import { ATTACHMENT_PROPERTY_TO_FIELD_NAME_MAPPING } from '../CascadingSiteProvider';

export const isEmptyColumn = item =>
  _.get(item, 'readOnly', false) && _.get(item, 'fieldName') !== BID_COLUMN_FIELD_NAME;
export const shouldDisableCascadingRow = ({ row, disableAllRows }) => {
  if (disableAllRows) {
    return true;
  }
  // NOTE - We use the site name row so we have a place to hold this metadata.
  return !_.get(_.first(row), 'siteScenario.bid');
};

// NOTE - Not in love with this, but we need to replicate this sort for the overlaying files.
export const sortSitesForCascadingFileActions = memoize(sites =>
  _.chain(sites)
    .compact()
    .sortBy(_.property(`${PROPERTY_NAME_FIELD}.value`))
    .value(),
);

// NOTE - These are the supported action fields right now. We can make this more flexible in the future.
const filterOutActionFields = fields =>
  _.reject(fields, ({ fieldName }) => _.includes(['Array Layout'], _.get(fieldName, 'value')));

// NOTE - Not a huge fan of this, but we have capped attachment work at the moment.
const getActionsHeader = ({ fileProperty }) =>
  ATTACHMENT_PROPERTY_TO_FIELD_NAME_MAPPING[fileProperty];

export function toDataSheetRows({
  siteScenarios,
  fields,
  fieldThresholds,
  bidKey = 'bid',
  horizontalScroll = false,
  disableAllRows = false,
  disableBid = false,
  hideBid = false,
  allowGivenFields = false,
  fileProperty,
  onBid,
  actionsRenderer,
}) {
  const content = disableRowsOnPredicate(
    _.chain(siteScenarios)
      .compact()
      .sortBy(_.property(`${PROPERTY_NAME_FIELD}.value`))
      .map(record => {
        const { id } = record;
        return _.filter(
          _.concat(
            {
              fieldName: PROPERTY_NAME_COLUMN_FIELD_NAME,
              value: getPropertyName(record),
              readOnly: true,
              width: PROPERTY_NAME_COLUMN_WIDTH,
              // NOTE - Not in love with this here, but I need a way to read this metadata
              siteScenario: {
                id,
                bid: !!_.get(record, `${bidKey}.value`),
              },
              valueViewer: DefaultRenderer,
            },
            fieldsAndRecordToRow({
              fields: filterOutActionFields(fields),
              record,
              fieldThresholds,
              width: horizontalScroll ? DATA_COLUMN_WIDTH : undefined,
              ignoreDataTypeInSorting: true,
            }),
            actionsRenderer && {
              fieldName: ACTIONS_COLUMN_FIELD_NAME,
              readOnly: true,
              value: record, // prevent row from being filtered
              valueViewer: withProps({ files: _.get(record, `${fileProperty}.value`, []) })(
                actionsRenderer,
              ),
              width: BID_COLUMN_WIDTH,
            },
            !hideBid && {
              fieldName: BID_COLUMN_FIELD_NAME,
              readOnly: true,
              siteScenario: {
                id,
                bid: !!_.get(record, `${bidKey}.value`),
                onBid: _.partial(onBid, id),
              },
              valueViewer: withProps({ disabled: disableBid })(BidRenderer),
              width: BID_COLUMN_WIDTH,
            },
          ),
        );
      })
      .map(allowGivenFields ? assignGivenAndEditableToRow : _.identity)
      .value(),
    row =>
      shouldDisableCascadingRow({
        row,
        disableAllRows,
      }),
  );

  return _.concat(
    [
      // HEADER
      matchHeaderToColumns({
        header: _.filter(
          _.concat(
            {
              value: PROPERTY_NAME_COLUMN_FIELD_NAME,
              fieldName: PROPERTY_NAME_COLUMN_FIELD_NAME,
              readOnly: true,
              width: BID_COLUMN_WIDTH,
              valueViewer: HeaderRenderer,
            },
            fieldsToHeader({
              fields: filterOutActionFields(fields),
              width: horizontalScroll ? DATA_COLUMN_WIDTH : undefined,
              ignoreDataTypeInSorting: true,
            }),
            {
              fieldName: ACTIONS_COLUMN_FIELD_NAME,
              readOnly: true,
              value: ACTIONS_COLUMN_FIELD_NAME,
              customHeader: getActionsHeader({ fileProperty }),
              valueViewer: HeaderRenderer,
              width: BID_COLUMN_WIDTH,
            },
            !hideBid && {
              value: BID_COLUMN_FIELD_NAME,
              fieldName: BID_COLUMN_FIELD_NAME,
              readOnly: true,
              width: BID_COLUMN_WIDTH,
              valueViewer: EmptyRenderer,
            },
          ),
        ),
        content,
      }),
    ],
    // CONTENT
    content,
  );
}

toDataSheetRows.propTypes = {
  siteScenarios: PropTypes.arrayOf(
    PropTypes.shape({
      bid: PropTypes.object,
      id: PropTypes.number,
      siteRfpName: PropTypes.shape({
        name: PropTypes.string,
        fid: PropTypes.number,
        type: PropTypes.string,
        required: PropTypes.number,
        value: PropTypes.string,
      }),
    }),
  ),
};
