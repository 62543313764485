import React from 'react';
import styled from 'styled-components';
import { Z_FIRST_TIER } from './Depth';

export const Clickable = styled.div`
  cursor: pointer;
`;

const ReadOnlyOverlay = styled.div`
  position: absolute; /* Sit on top of the page content */
  width: ${props => props.width || '100%'}; /* Full width (cover the whole page) */
  height: ${props => props.height || '100%'}; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.025); /* Black background with opacity */
  z-index: ${Z_FIRST_TIER}; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
`;

export const ReadOnlyContainer = ({ readOnly, children }) => {
  return (
    <div>
      {children}
      {readOnly && <ReadOnlyOverlay />}
    </div>
  );
};
