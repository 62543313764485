import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import DataSheet from '../../../components/common/DataSheet';
import ExpandCollapse from '../../../components/common/ExpandCollapse';
import { useBBEContext } from '../../../core/store';
import { TOGGLE_EXPAND_COLLAPSE } from '../../../core/actions';
import { expandCollapseByTitleSelector } from '../../../core/selectors';
import { ReadOnlyContainer } from '../../../components/common/Interaction';
import {
  removeDownloadUploadField,
  toDataSheetRows,
  valueRenderer,
} from './transformers/attachment';

export function DocumentDownloads({ attachments, fields, locked, onDownload }) {
  const { dispatch, state } = useBBEContext();
  const title = 'Document Downloads';

  const data = toDataSheetRows({
    attachments,
    fields: removeDownloadUploadField(fields),
    onDownload,
  });

  return (
    <ExpandCollapse
      title={title}
      openByDefault={expandCollapseByTitleSelector(state, title)}
      onToggle={() =>
        dispatch({
          type: TOGGLE_EXPAND_COLLAPSE,
          payload: title,
        })
      }
    >
      <ReadOnlyContainer readOnly={locked}>
        <Row>
          <Col xs={12}>
            <DataSheet className="rfpDataSheet" data={data} valueRenderer={valueRenderer} />
          </Col>
        </Row>
      </ReadOnlyContainer>
    </ExpandCollapse>
  );
}

DocumentDownloads.propTypes = {
  attachments: PropTypes.array,
  fields: PropTypes.array.isRequired,
  locked: PropTypes.bool,
};

DocumentDownloads.defaultProps = {
  attachments: null,
  locked: false,
};

export default DocumentDownloads;
