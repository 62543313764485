import _ from 'lodash';
import formatChanges from './formatting';
import {
  ATTACHMENT_PROPERTY_TO_FIELD_NAME_MAPPING,
  filesToCellChange,
} from '../CascadingSiteProvider';

export const cellsChangedHandler = ({ collection, callback }) => changes => {
  const formatted = formatChanges(collection, changes);
  callback(formatted);
};

export const bidHandler = ({ collection, callback, bidKey = 'bid' }) => id => {
  const siteScenario = _.find(collection, { id: Number(id) });
  const updated = _.merge({}, siteScenario, {
    [bidKey]: {
      value: !_.get(siteScenario, `${bidKey}.value`),
    },
  });
  callback(updated);
  return updated;
};

export const uploadHandler = ({ uploadFile, onCellsChanged, fileProperty }) => {
  const fieldName = ATTACHMENT_PROPERTY_TO_FIELD_NAME_MAPPING[fileProperty];
  if (fieldName) {
    return async ({ id, files }) => {
      const data = await uploadFile(id, files, _.kebabCase(fileProperty));
      if (data != null) {
        onCellsChanged(filesToCellChange(id, fieldName, data.files));
      }
    };
  } else {
    throw new Error(
      'You cannot instantiate an upload handler without a supported field name (Array Layout or Storage Layout).',
    );
  }
};
