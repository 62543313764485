import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col } from 'react-bootstrap';
import {
  CascadingArrayDesignProvider,
  CascadingStorageDesignProvider,
  CascadingProjectScheduleProvider,
} from './components/CascadingSiteProvider';
import { useAsyncCallBeforeClose } from '../../core/hooks/browser';
import { useAttachmentsState } from '../../state/attachment';
import { useBfQuestionsState } from '../../state/bfQuestion';
import { useConfirmationsState } from '../../state/confirmation';
import { useQuestionsState } from '../../state/question';
import { useRfpQuestionsState } from '../../state/rfpQuestion';
import { useSiteScenariosState } from '../../state/siteScenario';
import { useGetFieldsByOptInId } from '../../services/field';
import { useGetOptInById, useGetOptInsById } from '../../services/optIn';
import { useGetDefaultsByOptInId } from '../../services/combined';
import {
  useArrayDesignDetails,
  useProjectSchedule,
  useExportToCSV,
  useSaveSubmit,
  useStorageDesignDetails,
} from './hooks/rfp';
import Header from '../../components/Header/index';
import DocumentDownloads from './components/DocumentDownloads';
import DocumentUploads from './components/DocumentUploads';
import Confirmations from './components/Confirmations';
import Questions from './components/Questions';
import RfpQuestions from './components/RfpQuestions';
import Controls from './components/Controls';
import SiteList from './components/SiteList';
import BidForm from './components/BidForm';
import Loader, { StatefulLoadingProvider } from '../../components/Loader';
import WorkflowHistory from './components/WorkflowHistory';
import { SeparationRow, RightAlignedTwoColumns } from '../../components/common/Layout';
import ClarificationQuestions from './components/ClarificationQuestions';
import { mergeSavedAndDefaultCollections } from '../../utils/collection';
import { anyTrue, anyEmpty } from '../../utils/api';
import ActionTimer from '../../components/common/ActionTimer';
import { useAnyChangesSinceSave } from '../../core/hooks/anyChanges';
import { isDevelopment } from '../../utils/enviroment';
import { useBBEState, useBBEDispatch } from '../../core/store';
import { useExpandedSections } from './hooks/view';
import { useCreateOrUpdateSession, useGetSession } from '../../services/session';
import CannotLoadMessage from './components/CannotLoadMessage';
import MultiFileUploadStatus from '../Global/MultiFileUploadStatus';
import { NEWER_SESSION_REGISTERED } from '../../core/actions';
import { useNotifications } from '../../core/hooks/notifications';
import { useUploadFiles as useSiteScenarioUploadFiles } from '../../services/siteScenario';
import { useRFPQuery } from './hooks/query';
import ErrorContainer from '../Error/ErrorContainer';
import { useSaveHistory } from './hooks/saveHistory';
import { useQuery } from '../../core/hooks/url';
import {
  filterSitesForMatchingEnergyTechnology,
  initializeDefaultSiteScenarios,
} from './components/helpers/init';
import { rfpDataChecks } from '../../core/dataChecks';
import { useSubmitRfpQuestion } from '../../services/rfpQuestion';
import { useAttachmentDownloadFiles, useAttachmentUploadFiles } from '../../services/attachment';
import { SessionContext } from '../Session';
import { SOLAR_ENERGY_TECHNOLOGY, STORAGE_ENERGY_TECHNOLOGY } from './constants';

/* eslint-disable no-unused-vars */
export function RFP({
  userId,
  optInId,
  optIn,
  fields,
  defaultAttachments,
  defaultBfQuestions,
  defaultConfirmations,
  defaultQuestions,
  defaultRfpQuestions,
  defaultSites,
  defaultScenarios,
  defaultSiteScenarios,
  onRfpQuestionSubmit,
  session,
  locked,
  readOnly,
}) {
  /* eslint-enable no-unused-vars */
  const dispatch = useBBEDispatch();
  const [sendNotification] = useNotifications();

  // OPT-IN
  const customerRfp = _.get(optIn, 'customerRfp');
  const savedOptIn = _.get(optIn, 'saved');

  // EXPANDED STATE
  const bbeState = useBBEState();
  const expandedSections = _.get(bbeState, 'expandedSections');
  const saveCount = _.get(bbeState, 'saveCount', 0);

  // FIELDS
  const { sites: siteFields, scenarios: scenarioFields, siteScenarios: siteScenarioFields } =
    fields || {};

  // STATE
  const [
    cascadingArrayDesignDetails,
    customizeArrayDesignDetailsByScenario,
  ] = useArrayDesignDetails(
    mergeSavedAndDefaultCollections(
      _.get(savedOptIn, 'cascadingArrayDesignDetails'),
      filterSitesForMatchingEnergyTechnology(
        defaultSites,
        defaultScenarios,
        defaultSiteScenarios,
        SOLAR_ENERGY_TECHNOLOGY,
      ),
    ),
    _.get(savedOptIn, 'view.customizeArrayDesignDetailsByScenario', false),
  );
  const [
    cascadingStorageDesignDetails,
    customizeStorageDesignDetailsByScenario,
  ] = useStorageDesignDetails(
    mergeSavedAndDefaultCollections(
      _.get(savedOptIn, 'cascadingStorageDesignDetails'),
      filterSitesForMatchingEnergyTechnology(
        defaultSites,
        defaultScenarios,
        defaultSiteScenarios,
        STORAGE_ENERGY_TECHNOLOGY,
      ),
    ),
    _.get(savedOptIn, 'view.customizeStorageDesignDetailsByScenario', false),
  );
  const [cascadingProjectSchedule, customizeProjectScheduleByScenario] = useProjectSchedule(
    mergeSavedAndDefaultCollections(_.get(savedOptIn, 'cascadingProjectSchedule'), defaultSites),
    _.get(savedOptIn, 'view.customizeProjectScheduleByScenario', false),
  );
  const [
    downloadAttachments,
    uploadAttachments,
    updateAttachmentUploadedFile,
  ] = useAttachmentsState(
    mergeSavedAndDefaultCollections(_.get(savedOptIn, 'attachments'), defaultAttachments),
  );
  const [bfQuestions, setSingleBfQuestion, setBfQuestionAttachments] = useBfQuestionsState(
    mergeSavedAndDefaultCollections(_.get(savedOptIn, 'bfQuestions'), defaultBfQuestions),
  );
  const [confirmations, setSingleConfirmation] = useConfirmationsState(
    mergeSavedAndDefaultCollections(_.get(savedOptIn, 'confirmations'), defaultConfirmations),
  );
  const [questions, setSingleQuestion] = useQuestionsState(
    mergeSavedAndDefaultCollections(_.get(savedOptIn, 'questions'), defaultQuestions),
  );
  const [rfpQuestions, submitRfpQuestion] = useRfpQuestionsState(
    defaultRfpQuestions,
    onRfpQuestionSubmit,
  );
  const [
    siteScenarios,
    setSiteScenarioOnCellChange,
    cascadeChangesToAllSiteScenarios,
    cascadeBidToAllSiteScenarios,
  ] = useSiteScenariosState(
    mergeSavedAndDefaultCollections(_.get(savedOptIn, 'siteScenarios'), defaultSiteScenarios),
    defaultScenarios,
  );

  const onAttachmentDownload = useAttachmentDownloadFiles(optInId);
  const onAttachmentUpload = useAttachmentUploadFiles(optInId, {
    updateAttachmentUploadedFile,
  });

  const onExport = useExportToCSV({
    fields,
    siteRfps: defaultSites,
    siteScenarios,
    scenarios: defaultScenarios,
    confirmations,
    questions,
    bfQuestions,
  });

  const [onSave, onSubmit, onDelete] = useSaveSubmit(optInId, {
    id: optInId,
    userId,
    cascadingArrayDesignDetails,
    cascadingStorageDesignDetails,
    cascadingProjectSchedule,
    customerRfp,
    bfQuestions,
    confirmations,
    siteScenarios,
    questions,
    fields,
    scenarios: defaultScenarios,
    view: {
      customizeArrayDesignDetailsByScenario,
      customizeStorageDesignDetailsByScenario,
      customizeProjectScheduleByScenario,
      expandedSections,
    },
  });

  const [onCreateOrUpdateSession] = useCreateOrUpdateSession(
    {
      optInId,
      userId,
    },
    ({ newerSessionRegistered }) => {
      // NOTE - We send a notification to the user if we find a newer session.
      if (newerSessionRegistered) {
        dispatch({ type: NEWER_SESSION_REGISTERED });
        sendNotification('danger', {
          title: 'Browser Session Invalid',
          message:
            'The same form has been opened in another tab or browser window. The newest form results take precedence. This one will be locked.',
        });
      }
    },
  );

  const { data: savedOptIns } = useGetOptInsById(optInId, { saveCount });
  const onSaveHistory = useSaveHistory({ optInId, optIns: savedOptIns });
  const [anyChangesMadeSinceSave] = useAnyChangesSinceSave();

  useAsyncCallBeforeClose(_.constant(!isDevelopment() && !locked && anyChangesMadeSinceSave));

  return (
    <>
      {!locked && (
        <ActionTimer
          action={onCreateOrUpdateSession}
          callImmediately={true}
          interval={120 * 1000}
        />
      )}
      <Header>
        <RightAlignedTwoColumns>
          <Controls
            showSave={true}
            onSave={_.partial(onSave, true)}
            onSaveHistory={onSaveHistory}
            onSubmit={onSubmit}
            onExport={onExport}
            onDelete={onDelete}
            locked={locked}
          />
          <WorkflowHistory optIn={optIn} locked={locked} readOnly={readOnly} session={session} />
        </RightAlignedTwoColumns>
      </Header>
      <SeparationRow>
        <Col xs={12}>
          <h4>{_.get(optIn, 'customerRfp.rfpId.value')} Bid Form</h4>
          {!_.isEmpty(downloadAttachments) && (
            <DocumentDownloads
              attachments={downloadAttachments}
              onDownload={onAttachmentDownload}
              fields={_.get(fields, 'attachments')}
              locked={locked}
            />
          )}
          {!_.isEmpty(bfQuestions) && (
            <ClarificationQuestions
              bfQuestions={bfQuestions}
              onChangeBfQuestion={setSingleBfQuestion}
              onChangeBfQuestionAttachments={setBfQuestionAttachments}
              locked={locked}
            />
          )}
          {!_.isEmpty(confirmations) && (
            <Confirmations
              confirmations={confirmations}
              onChangeConfirmation={setSingleConfirmation}
              locked={locked}
            />
          )}
          {!_.isEmpty(uploadAttachments) && (
            <DocumentUploads
              attachments={uploadAttachments}
              onDownload={onAttachmentDownload}
              onUpload={onAttachmentUpload}
              fields={_.get(fields, 'attachments')}
              locked={locked}
            />
          )}
          <SiteList siteRfps={defaultSites} fields={siteFields} locked={locked} />
        </Col>
      </SeparationRow>
      <BidForm
        sites={defaultSites}
        scenarios={defaultScenarios}
        scenarioFields={scenarioFields}
        siteScenarios={siteScenarios}
        siteScenarioFields={siteScenarioFields}
        onChangeSiteScenario={setSiteScenarioOnCellChange}
        onCascadeChangeToSiteScenarios={cascadeChangesToAllSiteScenarios}
        onCascadeBidToSiteScenarios={cascadeBidToAllSiteScenarios}
        locked={locked}
      />
      <Questions questions={questions} locked={locked} onChangeQuestion={setSingleQuestion} />
      <RfpQuestions questions={rfpQuestions} locked={locked} onSubmit={submitRfpQuestion} />
      <MultiFileUploadStatus />
    </>
  );
}

RFP.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  optInId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  optIn: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  defaultAttachments: PropTypes.array,
  defaultBfQuestions: PropTypes.array,
  defaultConfirmations: PropTypes.array,
  defaultQuestions: PropTypes.array,
  defaultRfpQuestions: PropTypes.array,
  defaultSites: PropTypes.array,
  defaultScenarios: PropTypes.array,
  defaultSiteScenarios: PropTypes.array,
  onRfpQuestionSubmit: PropTypes.func,
  locked: PropTypes.bool,
  readOnly: PropTypes.bool,
};

RFP.defaultProps = {
  defaultAttachments: null,
  defaultBfQuestions: null,
  defaultConfirmations: null,
  defaultQuestions: null,
  defaultRfpQuestions: null,
  defaultSites: null,
  defaultScenarios: null,
  defaultSiteScenarios: null,
  onRfpQuestionSubmit: () => {},
  locked: false,
  readOnly: false,
};

function PreloadOptIn() {
  // STATE & UPLOADS
  const bbeState = useBBEState();
  const bbeDispatch = useBBEDispatch();
  const onUploadSiteScenarioUpload = useSiteScenarioUploadFiles({ cascading: true });

  const sessionContext = React.useContext(SessionContext);

  // OPT-IN
  const { optInId, userId, readOnly, overrideLock } = sessionContext.sessionData;
  const { overrideLock: overrideLockQueryParam, versionId } = useRFPQuery(); // eslint-disable-line no-unused-vars
  const { data: session, isLoading: isLoadingSession } = useGetSession({ optInId, userId });
  const { data: optIn, isLoading: isLoadingOptIn } =
    useGetOptInById(optInId, { ...useQuery(), versionId }) || {};

  // FIELDS
  const { data: fields, isLoading: isLoadingFields } = useGetFieldsByOptInId(optInId) || {};

  // todo: check if session has expired and resubmit for validation
  // DEFAULTS
  const {
    defaultAttachments,
    defaultBfQuestions,
    defaultConfirmations,
    defaultQuestions,
    defaultRfpQuestions,
    defaultSites,
    defaultScenarios,
    defaultSiteScenarios,
    isLoadingDefaults,
    errors,
  } = useGetDefaultsByOptInId(optInId, userId);

  // Methods
  const handleRfpQuestionSubmit = useSubmitRfpQuestion(optInId, userId);

  // EXPANDED STATE
  useExpandedSections(optIn);

  // LOCKING
  const locked =
    !overrideLock &&
    (readOnly ||
      (!(isDevelopment() && overrideLockQueryParam) &&
        (!_.get(session, 'valid') || bbeState.newerSessionRegistered)));

  if (errors.length > 0) {
    return (
      <ErrorContainer
        errors={errors.map(({ request, error }) => {
          const message = _.get(error, 'response.data.message', error.message);
          return `Error ${request}: ${message}`;
        })}
      />
    );
  }

  /* eslint-disable no-constant-condition */
  if (!optInId || !userId) {
    return (
      <CannotLoadMessage message="Request is missing the required values to match an opt-in to a user." />
    );
  } else if (
    anyTrue(isLoadingSession, isLoadingOptIn, isLoadingFields, isLoadingDefaults) ||
    anyEmpty(defaultSites, defaultScenarios, defaultSiteScenarios)
  ) {
    return <Loader size="lg" />;
  } else {
    // NOTE - This method is just to log out console warnings for missing data, just so we can debug when speaking to the Black Bear team.
    rfpDataChecks({
      optIn,
      sites: defaultSites,
      scenarios: defaultScenarios,
      siteScenarios: defaultSiteScenarios,
    });

    return (
      <CascadingArrayDesignProvider
        fields={_.get(fields, 'siteScenarios.arrayDesign')}
        dispatch={bbeDispatch}
        onUpload={onUploadSiteScenarioUpload}
      >
        <CascadingStorageDesignProvider
          fields={_.get(fields, 'siteScenarios.storageDesign')}
          dispatch={bbeDispatch}
          onUpload={onUploadSiteScenarioUpload}
        >
          <CascadingProjectScheduleProvider
            fields={_.get(fields, 'siteScenarios.projectSchedule')}
            dispatch={bbeDispatch}
          >
            <StatefulLoadingProvider>
              <RFP
                userId={userId}
                optInId={optInId}
                optIn={optIn}
                fields={fields}
                defaultAttachments={defaultAttachments}
                defaultBfQuestions={defaultBfQuestions}
                defaultConfirmations={defaultConfirmations}
                defaultQuestions={defaultQuestions}
                defaultRfpQuestions={defaultRfpQuestions}
                defaultSites={defaultSites}
                defaultScenarios={defaultScenarios}
                defaultSiteScenarios={initializeDefaultSiteScenarios(
                  _.get(optIn, 'saved'),
                  defaultScenarios,
                  defaultSiteScenarios,
                )}
                onRfpQuestionSubmit={handleRfpQuestionSubmit}
                session={session}
                locked={locked}
                readOnly={readOnly}
              />
            </StatefulLoadingProvider>
          </CascadingProjectScheduleProvider>
        </CascadingStorageDesignProvider>
      </CascadingArrayDesignProvider>
    );
  }
  /* eslint-enable no-constant-condition */
}

export default PreloadOptIn;
