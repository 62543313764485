import React, { useLayoutEffect, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useBBEState } from '../../../../core/store';

const getFirstRow = ref =>
  ref.current.getElementsByTagName('tr')[0] || { getElementsByTagName: _.noop };

export function MeasuredGridRows({ children }) {
  const ref = useRef(null);
  const [columnWidths, setColumnWidths] = useState([]);
  const [rowHeights, setRowHeights] = useState([]);
  const { expandedSections } = useBBEState();
  const [measureTrigger, setMeasureTrigger] = useState(0);
  // NOTE - This is a really nasty aspect of the measurement of the container
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const idA = setTimeout(() => setMeasureTrigger(measureTrigger + 1), 100);
    const idB = setTimeout(() => setMeasureTrigger(measureTrigger + 2), 500);
    const idC = setTimeout(() => setMeasureTrigger(measureTrigger + 3), 1000);
    return () => {
      clearTimeout(idA);
      clearTimeout(idB);
      clearTimeout(idC);
    };
  }, [expandedSections]);
  /* eslint-enable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    if (_.get(ref, 'current')) {
      const columnMeasurements = _.map(getFirstRow(ref).getElementsByTagName('td'), 'offsetWidth');
      const rowMeasurements = _.map(ref.current.getElementsByTagName('tr'), 'offsetHeight');
      // NOTE - This may be a strange artifact of the data sheet.
      if (!_.isEmpty(columnMeasurements) && _.every(columnMeasurements, val => val > 0)) {
        setColumnWidths(columnMeasurements);
      }
      if (!_.isEmpty(rowMeasurements) && _.every(rowMeasurements, val => val > 0)) {
        setRowHeights(rowMeasurements);
      }
    }
  }, [children, expandedSections, measureTrigger]);

  return <div ref={ref}>{children({ columnWidths, rowHeights })}</div>;
}

export default MeasuredGridRows;
