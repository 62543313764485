import numeral from 'numeral';

export function leadingZeroes(value, count, keepDecimals = false) {
  const parsed = keepDecimals ? parseFloat(String(value)) : parseInt(String(value), 10);
  const safeValue = isNaN(parsed) ? 0 : parsed;
  const [leftSide, rightSide] = String(safeValue).split('.');
  const numberLength = Math.max(leftSide.length, count);
  const leftSideFormat = new Array(numberLength).fill(0).join('');
  return numeral(safeValue).format(
    keepDecimals
      ? `${leftSideFormat}.${new Array(rightSide?.length || 0).fill(0).join('')}`
      : leftSideFormat,
  );
}
