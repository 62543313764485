import _ from 'lodash';
import { useState } from 'react';
import { getUrlPath } from '../utils/string';
import { replaceByProperty } from '../utils/collection';

export const AttachmentUploadDownload = {
  UPLOAD: 'Upload',
  DOWNLOAD: 'Download',
};

const mapAttachment = ({
  id,
  documentName,
  uploadDownload,
  attachment,
  uploadAttachment,
  dateModified,
}) => ({
  id,
  documentName,
  uploadDownload,
  attachment:
    uploadDownload.value === AttachmentUploadDownload.UPLOAD
      ? {
          ...attachment,
          value: {
            filename: uploadAttachment.value && getUrlPath(uploadAttachment.value),
            url: uploadAttachment.value,
          },
        } // Map uploadedAttachment text to the quickbase attachment format
      : attachment,
  dateModified,
});

export const isUploadAttachment = attachment =>
  attachment.uploadDownload.value === AttachmentUploadDownload.UPLOAD;

export const isDownloadAttachment = attachment => !isUploadAttachment(attachment);

export const useAttachmentsState = defaultAttachments => {
  const transformedDefaultAttachments = _.map(defaultAttachments, mapAttachment);
  const [attachments, setAttachments] = useState(transformedDefaultAttachments);

  const updateAttachmentUploadedFile = (id, value) => {
    const attachment = attachments.find(attachment => attachment.id === id);
    attachment.attachment.value = value;
    setAttachments(replaceByProperty(attachments, attachment, 'id'));
  };

  // NOTE - Not in love with this defaulting, but do not have another great way
  const [defaultReceived, setDefaultReceived] = useState(false);
  if (!_.isEmpty(transformedDefaultAttachments) && !defaultReceived) {
    setDefaultReceived(true);
    setAttachments(transformedDefaultAttachments);
  }

  return [
    attachments.filter(isDownloadAttachment), // Download attachments
    attachments.filter(isUploadAttachment), // Upload attachments
    updateAttachmentUploadedFile,
  ];
};
