import _ from 'lodash';
import { useState } from 'react';
import { replaceByProperty } from '../utils/collection';
import { useAnyChangesSinceSave } from '../core/hooks/anyChanges';
import { useUpdateQueue } from '../core/hooks/updateQueue';

export const useBfQuestionsState = defaultBfQuestions => {
  const [__, onChangesMade] = useAnyChangesSinceSave(); // eslint-disable-line no-unused-vars
  const transformedDefaultBfQuestions = _.map(
    defaultBfQuestions,
    ({ id, question, answer, bbeFile }) => ({
      id,
      question,
      bbeFile,
      answer: answer || /* DEFAULT VALUE */ {
        value: '',
        attachments: [],
      },
    }),
  );
  const [bfQuestions, setBfQuestions] = useState(transformedDefaultBfQuestions);
  const [attachmentUpdates, appendToAttachmentUpdates, markAttachmentUpdates] = useUpdateQueue();

  // NOTE - Not in love with this defaulting, but do not have another great way
  const [defaultReceived, setDefaultReceived] = useState(false);
  if (!_.isEmpty(transformedDefaultBfQuestions) && !defaultReceived) {
    setDefaultReceived(true);
    setBfQuestions(transformedDefaultBfQuestions);
  }

  // NOTE - Works down the state changes and causes no race condition for attachments. We could eliminate this with context. Potentially in a future refactor.
  if (!_.isEmpty(attachmentUpdates)) {
    const update = _.first(attachmentUpdates);
    setBfQuestions(replaceByProperty(bfQuestions, update, 'id'));
    markAttachmentUpdates(update);
    onChangesMade();
  }

  const setSingleBfQuestion = bfQuestion => {
    setBfQuestions(replaceByProperty(bfQuestions, bfQuestion, 'id'));
    // NOTE - Signals unsaved changes to the central State Provider.
    onChangesMade();
  };

  const setBfQuestionAttachments = (id, attachments) => {
    const bfQuestion = _.find(bfQuestions, { id });
    const updated = {
      ...bfQuestion,
      answer: {
        ...bfQuestion.answer,
        attachments,
      },
    };
    appendToAttachmentUpdates(updated);
  };

  return [bfQuestions, setSingleBfQuestion, setBfQuestionAttachments];
};
