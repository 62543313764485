const _ = require('lodash');

function fileComponents(filename) {
  const isHidden = /^\./.test(filename);
  const split = _.split(filename, '.');
  if (isHidden) {
    if (split.length >= 3) {
      return {
        filename: split.slice(0, -1).join('.'),
        extension: split.pop(),
      };
    }
    return {
      filename,
      extension: undefined,
    };
  } else if (split.length >= 2) {
    return {
      filename: split.slice(0, -1).join('.'),
      extension: split.pop(),
    };
  } else {
    return {
      filename,
      extension: undefined,
    };
  }
}

const appendedFileName = (name, appendToFilename) => {
  const date = new Date();
  const { filename, extension } = fileComponents(name);
  const appendedFileName = `${date.getTime()}-${filename}${
    appendToFilename ? `-${appendToFilename}` : ''
  }`;
  return `${appendedFileName}${extension ? `.${extension}` : ''}`;
};

module.exports = {
  fileComponents,
  appendedFileName,
};
