import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';
import { sessionId } from './session';
import useAPI from '../core/hooks/useApi';
import { useRFPQuery } from '../containers/RFP/hooks/query';

// NOTE - We don't handle errors here because they do not matter for usability
export const useGetOptInById = id => useAPI(`/api/opt-ins/${id}`);
export const useGetOptInsById = (id, { saveCount } = {}) =>
  useAPI(
    `/api/opt-ins?${qs.stringify(
      _.pickBy({
        id,
        saveCount,
      }),
    )}`,
  );
export const useUpdateOptInById = (id, query = {}, transition = _.noop) => {
  const { token } = useRFPQuery();
  return async (data, notifyUser = false) => {
    const response = await axios.request({
      url: `/api/opt-ins/${id}?${qs.stringify({ ...query, token })}`,
      method: 'PUT',
      data: {
        ...data,
        sessionId,
      },
    });
    await transition(_.get(response, 'data'), notifyUser);
    return response;
  };
};
export const useDeleteOptInById = (id, transition = _.noop) => {
  const { token } = useRFPQuery();
  return async () => {
    const response = await axios.delete(`/api/opt-ins/${id}?${qs.stringify({ token })}`);
    await transition(_.get(response, 'data'));
    return response;
  };
};
export const useUpdateOptInFromSaveHistory = (
  { optInId, versionId },
  onSuccess = _.noop,
  onError = _.noop,
) => {
  const { token } = useRFPQuery();
  return async savedOptInId => {
    try {
      const response = await axios.put(
        `/api/actions/opt-ins/${optInId}/update-from-save-history?${qs.stringify({ token })}`,
        {
          id: savedOptInId,
          from: versionId,
        },
      );
      await onSuccess(_.get(response, 'data'));
      return response;
    } catch (e) {
      await onError(e);
    }
  };
};
