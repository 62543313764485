import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { DATE_FORMAT } from '../../constants';

const StyledDatePicker = styled(DatePicker)`
  background-color: unset;
  border: none;
  height: 100%;
  width: 100%;
  cursor: pointer;
  text-align: center;
`;

const transformDateToTextFormat = date => {
  const isValid = moment(date).isValid();
  const formatted = moment(date).format(DATE_FORMAT);
  return isValid ? formatted : date;
};

const DateRenderer = ({ cell, onChange, onCommit }) => {
  const { value } = cell;
  const ref = useRef();
  const [readyForCommit, setReadyForCommit] = useState();

  useEffect(() => {
    if (ref.current) {
      const { input } = ref.current;
      input.focus();
    }
  }, [ref]);

  useEffect(() => {
    if (readyForCommit) {
      onCommit(readyForCommit);
    }
  }, [readyForCommit, onCommit]);

  const handleKeyDown = e => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      // If user types in his date then navigates away
      handleSelect(e.target.value);
    }
  };

  // select is triggered if user clicked a date in the picker
  const handleSelect = date => {
    const dateInFormat = transformDateToTextFormat(date);
    onChange(dateInFormat);

    // NOTE - This waits for a state change, so we do it via useEffect.
    setReadyForCommit(dateInFormat);
  };

  return (
    <StyledDatePicker
      open={true}
      ref={ref}
      selected={Date.parse(value)}
      placeholderText={cell.error ? value : null}
      onKeyDown={handleKeyDown}
      onSelect={handleSelect}
    />
  );
};

export default DateRenderer;
