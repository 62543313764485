import moment from 'moment';

export default function validate(change) {
  const { value, cell } = change;
  const { required, fieldType, min, max } = cell;

  // Return string for errors and empty string if ok.
  if (validateRequired(value, required)) {
    return 'required';
  } else if (value !== '') {
    return validateType(value, fieldType) || validateThresholds(value, min, max);
  } else {
    return '';
  }
}

function validateRequired(val, required) {
  return !!required && val === '';
}

function validateType(val, type) {
  switch (type) {
    case 'Text':
      return '';
    case 'Numeric':
    case 'Numeric - currency': {
      if (isNaN(val)) return 'invalid, must be number';
      break;
    }
    case 'Numeric - integer': {
      if (!Number.isInteger(Number(val))) return 'invalid, must be integer';
      break;
    }
    case 'Date': {
      if (!moment(val).isValid()) return 'invalid, must be a valid date';
      break;
    }
    default:
      return '';
    // If you want to get an idea of other field types we don't validate yet
    // uncomment line below and change some fields
    // default:
    // return `type unrecognised ${type}`;
  }
}

function validateThresholds(val, min, max) {
  return val < min || val > max ? `out of range, must be between ${min} and ${max}` : '';
}
