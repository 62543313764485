import React from 'react';
import styled from 'styled-components';

const RightAlignedCell = styled.div`
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10px;
  overflow: hidden;
`;

const LeftAlignedCell = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  padding-left: 10px;
  overflow: hidden;
`;

export const VerticallyHorizontallyCenteredCell = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const VerticallyCenteredRightAlignedCell = ({ children }) => (
  <VerticallyHorizontallyCenteredCell>
    <RightAlignedCell>{children}</RightAlignedCell>
  </VerticallyHorizontallyCenteredCell>
);

export const VerticallyCenteredLeftAlignedCell = ({ children }) => (
  <VerticallyHorizontallyCenteredCell>
    <LeftAlignedCell>{children}</LeftAlignedCell>
  </VerticallyHorizontallyCenteredCell>
);
