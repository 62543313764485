import React from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { useNotifications } from '../../../core/hooks/notifications';
import { useUpdateOptInFromSaveHistory } from '../../../services/optIn';
import { mergeQueryWithLocation, useRFPQuery } from './query';

const DATE_FORMAT = 'dddd, MMMM Do YYYY, h:mm:ss a';

function SaveHistory({ optIns, onSelect }) {
  return (
    <Form.Control as="select" onChange={e => onSelect(e.target.value)}>
      {[<option key={0}></option>].concat(
        _.map(optIns, ({ saved }) => (
          <option key={saved._id} value={saved._id}>
            {moment(saved.created).format(DATE_FORMAT)}
          </option>
        )),
      )}
    </Form.Control>
  );
}

export const useSaveHistory = ({ optInId, optIns }) => {
  const [sendNotification, sendConfirmation, setMetadata] = useNotifications();
  const { versionId } = useRFPQuery();
  const onSuccess = ({ _id } = {}) =>
    window.location.replace(mergeQueryWithLocation({ versionId: _id }));
  const onError = () =>
    sendNotification('danger', {
      title: 'Saved History Failed',
      message: 'Pulling the saved record failed. Please reach out to support.',
    });
  const updateOptInFromSaveHistory = useUpdateOptInFromSaveHistory(
    { optInId, versionId },
    onSuccess,
    onError,
  );

  const onSaveHistory = () => {
    sendConfirmation('warning', {
      title: 'Version History',
      message: <SaveHistory optIns={optIns} onSelect={id => setMetadata({ id })} />,
      okLabel: 'Revert',
      onOk: ({ id }) => updateOptInFromSaveHistory(id),
    });
  };

  return onSaveHistory;
};
