import _ from 'lodash';

export function parseErrors(data) {
  if (!data) return;
  const allErrors = extractErrors(data);
  if (!allErrors.length) return;
  const reduced = reduceErrors(allErrors);
  const composedErrorMessages = composeMessages(reduced);
  return composedErrorMessages;
}

export function countErrors(data) {
  if (!data) return 0;
  const allErrors = extractErrors(data);
  return allErrors.length;
}

function extractErrors(data) {
  const allErrors = [];
  _.map(data, row =>
    _.map(row, field => {
      if (field.error) allErrors.push(field.error);
    }),
  );
  return allErrors;
}

function reduceErrors(errors) {
  const uniqueErrors = _.uniqBy(errors);
  const reduced = _.map(uniqueErrors, error => ({
    count: _.filter(errors, e => e === error).length,
    message: error,
  }));
  return reduced;
}

function composeMessages(errors) {
  return _.map(errors, e => writeErrorMessage(e.count, e.message));
}

function writeErrorMessage(count, message) {
  const isPlural = count > 1;
  return `${count} field${isPlural ? 's' : ''} ${message}.`;
}

export function containsInvalidOrUnfilledValues(
  data,
  ignoreForSubmissionPredicate = _.constant(false),
) {
  const checkFieldForUnfilledAndErrors = field => {
    const val = _.get(field, 'value');
    // don't do boolean type cast because some fields don't have value (id & rid) or provide 0 or false (bid)
    // database provides '~' in value to designate blank fields
    const fieldUnfilledOrError = val === '' || val === '~' || !!_.get(field, 'error');
    return fieldUnfilledOrError;
  };
  // we only check site scenarios because cascading data ends up in site scenarios anyway
  const checkSiteScenario = siteScenario => {
    if (!siteScenario.bid.value) return false;
    return _.values(siteScenario)
      .filter(field => !ignoreForSubmissionPredicate(siteScenario, field))
      .some(checkFieldForUnfilledAndErrors);
  };
  const checkQuestionOrBfQuestion = question => _.isEmpty(_.get(question, 'answer.value'));
  const unfilledSiteScenarioFields = data.siteScenarios.some(checkSiteScenario);
  const unfilledBfQuestions = data.bfQuestions.some(checkQuestionOrBfQuestion);
  const unfilledQuestions = data.questions.some(checkQuestionOrBfQuestion);
  return unfilledSiteScenarioFields || unfilledBfQuestions || unfilledQuestions;
}
