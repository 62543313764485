import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { VerticallyHorizontallyCenteredCell } from '../../../../components/common/TableLayout';

export function DataCellRenderer({ cell }) {
  return (
    <VerticallyHorizontallyCenteredCell>{_.get(cell, 'value')}</VerticallyHorizontallyCenteredCell>
  );
}

DataCellRenderer.propTypes = {
  cell: PropTypes.object,
};

DataCellRenderer.defaultProps = {
  cell: null,
};

export default DataCellRenderer;
