import React, { useContext } from 'react';
import numeral from 'numeral';
import styled from 'styled-components';
import _ from 'lodash';
import { Row, Col, Toast } from 'react-bootstrap';
import { ProgressBar } from '../../components/common/BlackBearEnergy';
import { FileUploadContext } from '../../components/FileUpload';

const BottomRightAbsoluteContainer = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 350px;
  z-index: 9999999;
`;

function FileUploadToast({ fileName, percentComplete }) {
  return (
    <Row>
      <Col xs={12}>
        <Toast show={true}>
          <Toast.Header closeButton={false}>{fileName}</Toast.Header>
          <Toast.Body>
            <ProgressBar
              now={percentComplete}
              label={numeral(percentComplete / 100).format('0%')}
            />
          </Toast.Body>
        </Toast>
      </Col>
    </Row>
  );
}

function MultiFileUploadStatus() {
  const { files } = useContext(FileUploadContext);
  return (
    <BottomRightAbsoluteContainer>
      {_.map(files, (percentComplete, fileName) => (
        <FileUploadToast key={fileName} fileName={fileName} percentComplete={percentComplete} />
      ))}
    </BottomRightAbsoluteContainer>
  );
}

MultiFileUploadStatus.propTypes = {};

MultiFileUploadStatus.defaultProps = {};

export default MultiFileUploadStatus;
