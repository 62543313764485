import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '../../../components/common/BlackBearEnergy';
import SecondaryControls from './SecondaryControls';

const marginRight = { marginRight: '15px' };

export function Controls({
  showSave,
  locked,
  onSave,
  onSaveHistory,
  onSubmit,
  onDelete,
  onExport,
}) {
  return (
    <div>
      {!locked && showSave && (
        <Button onClick={onSave} style={marginRight}>
          Save & Continue Later
        </Button>
      )}
      {!locked && <Button onClick={onSubmit}>Submit</Button>}
      <SecondaryControls
        locked={locked}
        onSaveHistory={onSaveHistory}
        onDelete={onDelete}
        onExport={onExport}
      />
    </div>
  );
}

Controls.propTypes = {
  showSave: PropTypes.bool,
  locked: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onSaveHistory: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onExport: PropTypes.func,
};

Controls.defaultProps = {
  showSave: false,
  locked: false,
  onDelete: _.noop,
  onExport: _.noop,
};

export default Controls;
