import styled from 'styled-components';
import {
  Button as BootstrapButton,
  Spinner as BootstrapSpinner,
  ProgressBar as BootstrapProgressBar,
} from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';

const WHITE = '#FFFFFF';
const BBE_PINK = '#CC4893';

const Button = styled(BootstrapButton)`
  border-radius: 0;
  background-color: ${WHITE} !important;
  border-color: ${BBE_PINK} !important;
  color: ${BBE_PINK} !important;

  &:hover,
  &.on {
    background-color: ${BBE_PINK} !important;
    color: ${WHITE} !important;
  }

  &.off {
    background-color: ${WHITE} !important;
    color: ${BBE_PINK} !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(199, 76, 145, 0.5) !important;
  }
`;

const InvertedButton = styled(Button)`
  background-color: ${BBE_PINK} !important;
  color: ${WHITE} !important;

  &:hover {
    background-color: ${WHITE} !important;
    color: ${BBE_PINK} !important;
  }
`;

const ButtonLink = styled(BootstrapButton)`
  color: ${BBE_PINK} !important;
`;

const DownIcon = styled(IoIosArrowDown)`
  float: right;
  margin-top: 5px;
  color: ${BBE_PINK} !important;
`;

const Spinner = styled(BootstrapSpinner)`
  background-color: ${BBE_PINK} !important;
  color: ${BBE_PINK} !important;
`;

const Text = styled.div`
  color: ${BBE_PINK} !important;
`;

const ProgressBar = styled(BootstrapProgressBar)`
  .progress-bar {
    background-color: ${BBE_PINK};
  }
`;

export { BBE_PINK, Button, InvertedButton, ButtonLink, DownIcon, ProgressBar, Spinner, Text };
