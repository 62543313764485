import _ from 'lodash';
import { memoize } from '../../../../utils/cache';
import {
  SOLAR_ENERGY_TECHNOLOGY,
  STORAGE_ENERGY_TECHNOLOGY,
  SOLAR_AND_STORAGE_ENERGY_TECHNOLOGY,
} from '../../constants';

const groupSiteScenariosByScenarioId = memoize(siteScenarios =>
  _.groupBy(siteScenarios, _.property('relatedScenario.value')),
);

export const containsSolar = memoize((scenarios, siteScenarios) => {
  const indexedSiteScenarios = groupSiteScenariosByScenarioId(siteScenarios);
  return _.chain(scenarios)
    .compact()
    .some(scenario => {
      const siteScenarios = indexedSiteScenarios[scenario.id];
      return isSolar(getScenarioEnergyTechnology(scenario, siteScenarios));
    })
    .value();
});

export const containsStorage = memoize((scenarios, siteScenarios) => {
  const indexedSiteScenarios = groupSiteScenariosByScenarioId(siteScenarios);
  return _.chain(scenarios)
    .compact()
    .some(scenario => {
      const siteScenarios = indexedSiteScenarios[scenario.id];
      return isStorage(getScenarioEnergyTechnology(scenario, siteScenarios));
    })
    .value();
});

export const getScenarioName = memoize((scenario, siteScenarios) => {
  return (
    _.get(scenario, 'scenarioName.value') ||
    _.get(
      _.find(siteScenarios, siteScenario => _.get(siteScenario, 'scenarioName.value')),
      'scenarioName.value',
    ) ||
    'No Scenario Name Provided'
  );
});

export const getScenarioEnergyTechnology = memoize((scenario, siteScenarios) => {
  return _.toLower(
    _.get(scenario, 'energyTechnology.value') ||
      _.get(
        _.find(siteScenarios, siteScenario =>
          _.get(siteScenario, 'scenarioEnergyTechnology.value'),
        ),
        'scenarioEnergyTechnology.value',
      ),
  );
});

export const isSolar = memoize(energyTechnology =>
  _.includes(
    [SOLAR_ENERGY_TECHNOLOGY, SOLAR_AND_STORAGE_ENERGY_TECHNOLOGY],
    _.toLower(energyTechnology),
  ),
);

export const isStorage = memoize(energyTechnology =>
  _.includes(
    [STORAGE_ENERGY_TECHNOLOGY, SOLAR_AND_STORAGE_ENERGY_TECHNOLOGY],
    _.toLower(energyTechnology),
  ),
);

export const isCustom = memoize(
  energyTechnology =>
    !_.includes(
      [SOLAR_ENERGY_TECHNOLOGY, STORAGE_ENERGY_TECHNOLOGY, SOLAR_AND_STORAGE_ENERGY_TECHNOLOGY],
      _.toLower(energyTechnology),
    ),
);

export const customFieldsFromScenarios = memoize(scenarios =>
  _.chain(scenarios)
    .map('customFields')
    .flatten()
    .uniqBy('fieldId.value')
    .value(),
);
