import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import DataSheet from '../../../components/common/DataSheet';
import { Button } from '../../../components/common/BlackBearEnergy';
import { useGetFieldThresholds } from '../../../services/fieldThreshold';
import {
  sortSitesForCascadingFileActions,
  toDataSheetRows as siteScenariosToDataSheetRows,
} from './transformers/cascadingSiteScenario';
import { valueRenderer as siteScenarioValueRenderer } from './transformers/siteScenario';
import { cellsChangedHandler, bidHandler } from './helpers/tableHandlers';
import { parseErrors, countErrors } from './helpers/errors';
import DataCellRenderer from './renderers/DataCellRenderer';
import ErrorContainer from '../../Error/ErrorContainer';
import { useNotifications } from '../../../core/hooks/notifications';
import { useRemoveFiles } from '../../../services/siteScenario';
import { filesToCellChange } from './CascadingSiteProvider';
import EmptyRenderer from './renderers/EmptyRenderer';
import { RelativeContainer } from '../../../components/common/Layout';
import CascadingFileActionsOverlay from './file/CascadingFileActionsOverlay';
import MeasuredGridRows from './layout/MeasuredGridRows';

const LightMargin = styled.div`
  margin-top: 10px;
`;

function CustomizeButton({ customize, fieldType, onClick }) {
  return (
    <LightMargin>
      <Button className={customize ? 'on' : 'off'} onClick={onClick} block>
        Customize {fieldType} by Scenario ({customize ? 'On' : 'Off'})
      </Button>
    </LightMargin>
  );
}

export function CascadingSiteDataSheet({
  sites,
  fields,
  title,
  fieldType,
  energyTechnology,
  customizeByScenario,
  disableAllRows = false,
  disableBid = false,
  hideBid = false,
  allowGivenFields = false,
  onChangeSite,
  onChangeSiteFiles,
  onCascadeChanges,
  onCascadeBid,
  onCustomizeByScenario,
  showCustomize,
  overrideCustomButtonSizing,
  showActions,
  fileProperty,
  setErrorCount = _.noop,
}) {
  // todo: error handler
  const onCellsChanged = changes => {
    // NOTE - Takes care of the baseline cell behavior
    cellsChangedHandler({
      collection: sites,
      callback: onChangeSite,
    })(changes);
    // NOTE - Assures that only the ones that allow customization can cascade
    if (!customizeByScenario && showCustomize) {
      onCascadeChanges(changes, energyTechnology);
    }
  };
  const onBid = (...args) => {
    const updated = bidHandler({
      collection: sites,
      callback: onChangeSite,
    })(...args);
    if (!customizeByScenario && showCustomize) {
      const id = _.first(args);
      onCascadeBid([
        {
          energyTechnology,
          site: _.find(sites, { id: Number(id) }),
          updated,
        },
      ]);
    }
  };

  // NOTE - showCustomize is a corollary for the cascading nature of a set of rows.
  const removeFile = useRemoveFiles({ cascading: showCustomize });
  const [sendNotification] = useNotifications();

  // NOTE - This logic, critically was moved up the provider in order to do the offline processing of multiple files.
  const handleUploadFile = ({ id }, { files }) => {
    onChangeSiteFiles({
      id,
      files,
      fileProperty,
      energyTechnology,
      onCascadeChanges,
    });
  };

  // TODO - We should not have an async method running here with a callback (because of state render timing issues). This one is okay because the remove happens fairly quick.
  const handleDeleteFile = async ({ id }, { fileName }) => {
    await removeFile(id, fileName);
    onCellsChanged(filesToCellChange(id, fileProperty, null));
    sendNotification('warning', {
      title: 'File Removed',
      message: `File removed for site scenario: ${fileName}`,
    });
  };
  const { data: fieldThresholds } = useGetFieldThresholds() || {};

  const data = siteScenariosToDataSheetRows({
    siteScenarios: sites,
    disableAllRows,
    disableBid,
    hideBid,
    allowGivenFields,
    fields,
    fieldThresholds,
    onBid,
    fileProperty,
    // NOTE - This is very strange, but we need to style these file drops as an overlay.
    actionsRenderer: showActions && EmptyRenderer,
  });

  const errors = parseErrors(data);
  setErrorCount(countErrors(data));

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <RelativeContainer>
      <MeasuredGridRows>
        {({ rowHeights, columnWidths }) => (
          <Row>
            <Col xs={12}>
              {title && <h6>{title}</h6>}
              <DataSheet
                className="rfpDataSheet"
                data={data}
                valueRenderer={siteScenarioValueRenderer}
                valueViewer={DataCellRenderer}
                onCellsChanged={onCellsChanged}
              />
            </Col>
            {showActions && (
              <CascadingFileActionsOverlay
                disabled={customizeByScenario}
                hasTitle={!!title}
                hideBid={hideBid}
                columnWidths={columnWidths}
                rowHeights={rowHeights}
                entities={sortSitesForCascadingFileActions(sites)}
                fileProperty={fileProperty}
                onDrop={handleUploadFile}
                onRemove={handleDeleteFile}
              />
            )}
          </Row>
        )}
      </MeasuredGridRows>
      {errors && (
        <Row>
          <Col>
            <ErrorContainer errors={errors} />
          </Col>
        </Row>
      )}
      {showCustomize && (
        <Row>
          <Col
            {...(overrideCustomButtonSizing || {
              xs: {
                span: 8,
                offset: 4,
              },
              xl: {
                span: 4,
                offset: 8,
              },
            })}
          >
            <CustomizeButton
              customize={customizeByScenario}
              fieldType={fieldType}
              onClick={_.partial(onCustomizeByScenario, {
                customizeByScenario: !customizeByScenario,
                onReCascadeAllChanges: onCascadeChanges,
                energyTechnology,
              })}
            />
          </Col>
        </Row>
      )}
    </RelativeContainer>
  );
  /* eslint-enable react/jsx-props-no-spreading */
}

CascadingSiteDataSheet.propTypes = {
  topLevelSites: PropTypes.array,
  sites: PropTypes.array,
  fields: PropTypes.array,
  title: PropTypes.string,
  fieldType: PropTypes.string,
  energyTechnology: PropTypes.string,
  customizeByScenario: PropTypes.bool,
  onChangeSite: PropTypes.func,
  onChangeSiteFiles: PropTypes.func,
  onCascadeBid: PropTypes.func,
  onCustomizeByScenario: PropTypes.func,
  showCustomize: PropTypes.bool,
  showActions: PropTypes.bool,
};

CascadingSiteDataSheet.defaultProps = {
  topLevelSites: null,
  sites: null,
  fields: null,
  title: null,
  fieldType: null,
  energyTechnology: null,
  customizeByScenario: false,
  onChangeSite: _.noop,
  onChangeSiteFiles: _.noop,
  onCascadeBid: _.noop,
  onCustomizeByScenario: _.noop,
  showCustomize: false,
  showActions: false,
};

export default CascadingSiteDataSheet;
