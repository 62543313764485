import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const styles = {
  container: provided => ({
    ...provided,
    width: '100%',
    height: '32px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 0 0 10px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '100%',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: provided => ({
    ...provided,
    minHeight: 'unset',
    height: '100%',
    border: 'none',
    borderRadius: '0',
    backgroundColor: 'transparent',
  }),
  menu: provided => ({
    ...provided,
    marginTop: '2px',
    borderRadius: '0',
  }),
  menuList: provided => ({
    ...provided,
    padding: '0',
  }),
  option: provided => ({
    ...provided,
    height: '32px',
    padding: '0 10px',
    lineHeight: '32px',
    textAlign: 'left',
  }),
};

const MultiChoiceRenderer = ({ value, options, onChange, onCommit }) => {
  const [readyForCommit, setReadyForCommit] = useState();
  useEffect(() => {
    if (readyForCommit) {
      onCommit(readyForCommit);
    }
  }, [readyForCommit, onCommit]);

  const handleChange = opt => {
    onChange(opt.value);
    setReadyForCommit(opt.value);
  };

  return (
    <Select
      autoFocus
      defaultMenuIsOpen
      isSearchable={false}
      value={value}
      styles={styles}
      onChange={handleChange}
      options={options.map(o => ({ value: o, label: o }))}
    />
  );
};

MultiChoiceRenderer.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};

export default MultiChoiceRenderer;
