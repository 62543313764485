import _ from 'lodash';
import { useState } from 'react';

export const useRfpQuestionsState = (defaultQuestions, handleSubmit) => {
  const transformedDefaultQuestions = _.map(
    defaultQuestions,
    ({ id, questionNotice, answerDescription, submittedOnNew }) => ({
      id,
      question: questionNotice,
      answer: answerDescription,
      submissionDate: submittedOnNew,
    }),
  );
  const [questions, setQuestions] = useState(transformedDefaultQuestions);

  // NOTE - Not in love with this defaulting, but do not have another great way
  const [defaultReceived, setDefaultReceived] = useState(false);
  if (!_.isEmpty(transformedDefaultQuestions) && !defaultReceived) {
    setDefaultReceived(true);
    setQuestions(transformedDefaultQuestions);
  }

  const submitQuestion = async value => {
    await handleSubmit(value);
    setQuestions(questions => [
      ...questions,
      {
        id: null,
        question: { value },
        submissionDate: { type: 'date', value: new Date().getTime() },
      },
    ]);
  };

  return [questions, submitQuestion];
};
