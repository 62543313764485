import React from 'react';
import styled from 'styled-components';

const SuccessText = styled.h4`
  text-align: center;
`;

export function Success() {
  return (
    <div>
      <SuccessText>
        You have successfully submitted your proposal. Please stay tuned for feedback from the Black
        Bear Energy team.
      </SuccessText>
    </div>
  );
}

Success.propTypes = {};

Success.defaultProps = {};

export default Success;
