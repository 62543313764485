import _ from 'lodash';
import { isEmptyReadOnlyForSubmission } from './submission';
import {
  SOLAR_ENERGY_TECHNOLOGY,
  STORAGE_ENERGY_TECHNOLOGY,
  SOLAR_AND_STORAGE_ENERGY_TECHNOLOGY,
} from '../../constants';
import { getScenarioEnergyTechnology, isSolar, isStorage } from './scenario';

const fieldIdKeyGetter = ({ fieldId }) => _.get(fieldId, 'value');

const energyTechnologyMatches = ({ energyTechnology, siteScenario }) => {
  const siteScenarioEnergyTechnology = getScenarioEnergyTechnology(null, [siteScenario]);
  if (energyTechnology === SOLAR_ENERGY_TECHNOLOGY) {
    return isSolar(siteScenarioEnergyTechnology);
  } else if (energyTechnology === STORAGE_ENERGY_TECHNOLOGY) {
    return isStorage(siteScenarioEnergyTechnology);
  }
  return true;
};

export function createIgnoreForSubmissionPredicate({ fields, customFields }) {
  const indexedArrayDesignFields = _.keyBy(
    _.get(fields, 'siteScenarios.arrayDesign'),
    fieldIdKeyGetter,
  );
  const indexedStorageDesignFields = _.keyBy(
    _.get(fields, 'siteScenarios.storageDesign'),
    fieldIdKeyGetter,
  );
  const indexedNonEnergyTypeSpecificFields = _.chain(
    _.get(fields, 'siteScenarios.bidSubmissionScenario'),
  )
    .concat(_.get(fields, 'siteScenarios.projectSchedule'))
    .keyBy(fieldIdKeyGetter)
    .value();
  const indexedCustomFields = _.chain(customFields)
    .compact()
    .keyBy(({ fieldId }) => _.get(fieldId, 'value'))
    .value();

  const getFieldAndEnergyTechnology = ({ fid, custom }) => {
    if (custom) {
      return {
        energyTechnology: SOLAR_AND_STORAGE_ENERGY_TECHNOLOGY,
        field: indexedCustomFields[fid],
      };
    } else if (indexedArrayDesignFields[fid]) {
      return {
        energyTechnology: SOLAR_ENERGY_TECHNOLOGY,
        field: indexedArrayDesignFields[fid],
      };
    } else if (indexedStorageDesignFields[fid]) {
      return {
        energyTechnology: STORAGE_ENERGY_TECHNOLOGY,
        field: indexedStorageDesignFields[fid],
      };
    }
    return {
      energyTechnology: SOLAR_AND_STORAGE_ENERGY_TECHNOLOGY,
      field: indexedNonEnergyTypeSpecificFields[fid],
    };
  };

  return (siteScenario, { fid, custom, value }) => {
    const { energyTechnology, field } = getFieldAndEnergyTechnology({
      fid,
      custom,
    });
    const dataType = _.get(field, 'dataType.value');
    return (
      !field ||
      !energyTechnologyMatches({ energyTechnology, siteScenario }) ||
      isEmptyReadOnlyForSubmission({
        dataType,
        custom,
        value,
      })
    );
  };
}

export function isValidFieldChange(value, field) {
  if (_.toLower(_.get(field, 'fieldType.value')) === 'text - multi-choice') {
    const options = _.chain(_.get(field, 'multiChoiceOptions.value'))
      .split(',')
      .map(_.trim)
      .value();
    return _.includes(options, value);
  } else {
    return true;
  }
}
