import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { useBBEState } from '../../../core/store';

const TextContainer = styled.div`
  text-align: right;
  margin-top: 8px;
`;

const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss a';

function getMessage(session, { lastSaved, lastSubmitted } = {}, locked = false, readOnly = false) {
  const email = _.get(session, 'user.email');
  const phone = _.get(session, 'user.cellPhone');
  return _.chain([
    // NOTE - Because a save is a submit, this is valid.
    (lastSaved || lastSubmitted) && (
      <div key={0}>
        {`Last Saved: ${moment(lastSaved || lastSubmitted).format(DATE_TIME_FORMAT)}`}
      </div>
    ),
    lastSubmitted && (
      <div key={1}>{`Last Submitted: ${moment(lastSubmitted).format(DATE_TIME_FORMAT)}`}</div>
    ),
    readOnly && <div key="readOnly">This form is read-only.</div>,
    locked &&
      !readOnly &&
      _.filter([
        <div key="locked">Currently read only</div>,
        email && <div key="lockedContact">Email: {email}</div>,
        phone && <div key="lockedContact">Phone: {phone}</div>,
      ]),
  ])
    .filter()
    .flatten()
    .value();
}

function WorkflowHistory({ optIn, locked, readOnly, session }) {
  const { recentlySavedOptIn } = useBBEState();
  // NOTE - We merge the two to get the last saved and last submitted dates.
  return (
    <TextContainer>
      {getMessage(session, _.merge({}, recentlySavedOptIn, optIn) || {}, locked, readOnly)}
    </TextContainer>
  );
}

/* eslint-disable react/no-unused-prop-types */
WorkflowHistory.propTypes = {
  optIn: PropTypes.object,
  locked: PropTypes.bool,
  readOnly: PropTypes.bool,
};

WorkflowHistory.defaultProps = {
  optIn: null,
  locked: false,
  readOnly: false,
};
/* eslint-enable react/no-unused-prop-types */

export default WorkflowHistory;
