import { useState, useEffect } from 'react';
import _ from 'lodash';
import { ensureUrlToken } from '../core/hooks/useApi';
import { useRFPQuery } from '../containers/RFP/hooks/query';
import api from './api';

const getFieldThresholds = _.once(token => api.get(ensureUrlToken('/field-thresholds', token)));

// TODO - We need to standardize more around our API calls and resources.
export const useGetFieldThresholds = () => {
  const [fieldThresholds, setFieldThresholds] = useState();
  const { token } = useRFPQuery();

  // NOTE - We use this approach here so the call is made once.
  useEffect(() => {
    if (token) {
      getFieldThresholds(token).then(({ data }) => setFieldThresholds(data));
    }
  }, [token]);

  return fieldThresholds;
};
