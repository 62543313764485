export const roundInt = val => Math.round(val);
export const roundShort = val => Number(val).toFixed(2);
export const roundLong = val => Number(val).toFixed(4);

const roundByType = (val, type) => {
  if (/integer/i.test(type) && !isNaN(val) && val !== '') {
    return roundInt(val);
  }
  if (/short decimal/i.test(type) && !isNaN(val) && val !== '') {
    return roundShort(val);
  }
  if (/long decimal/i.test(type) && !isNaN(val) && val !== '') {
    return roundLong(val);
  }
  return val;
};

export default roundByType;
