import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { VerticallyHorizontallyCenteredCell } from '../../../../components/common/TableLayout';
import { InvertedButton } from '../../../../components/common/BlackBearEnergy';
import { Z_SECOND_TIER } from '../../../../components/common/Depth';

const RendererButton = styled(InvertedButton)`
  z-index: ${Z_SECOND_TIER};
`;

// NOTE - We only support one kind of URL field at the moment.
const UrlRenderer = ({ value }) => {
  if (value) {
    const onClick = e => {
      e.stopPropagation();
      e.preventDefault();
      return window.open(value, '_blank');
    };

    return (
      <VerticallyHorizontallyCenteredCell>
        <RendererButton onClick={onClick}>View Site</RendererButton>
      </VerticallyHorizontallyCenteredCell>
    );
  }
  return null;
};

UrlRenderer.propTypes = {
  value: PropTypes.string,
};

UrlRenderer.defaultProps = {
  value: null,
};

export default UrlRenderer;
