import React from 'react';
import styled from 'styled-components';

const NotAuthorizedText = styled.h4`
  text-align: center;
`;

export function NotAuthorized() {
  return (
    <div>
      <NotAuthorizedText>You are not authorized to access this application.</NotAuthorizedText>
    </div>
  );
}

NotAuthorized.propTypes = {};

NotAuthorized.defaultProps = {};

export default NotAuthorized;
