import React, { useState, createContext, useContext } from 'react';
import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { SeparationRow } from './common/Layout';
import { Spinner, Text } from './common/BlackBearEnergy';
import { useWindowSize } from '../core/hooks/windowSize';
import { ReadOnlyContainer } from './common/Interaction';

const Fixed = styled.div`
  position: fixed;
  width: ${props => props.width}px;
  top: ${props => props.top - 56}px;
  z-index: 9999999999;
`;

const FullyCentered = styled.div`
  display: flex;
  justify-content: center;
  color: #51a451;
`;

export function NoMarginLoader({ count = 3, ...rest }) {
  return (
    <Row>
      <Col xs={12}>
        <FullyCentered>
          {_.times(count, key => (
            <Spinner key={key} variant="success" animation="grow" {...rest} /> // eslint-disable-line react/jsx-props-no-spreading
          ))}
        </FullyCentered>
      </Col>
      <Col>
        <FullyCentered>
          <i>
            <Text>(loading)</Text>
          </i>
        </FullyCentered>
      </Col>
    </Row>
  );
}

export function Loader({ count = 3, ...rest }) {
  const { width, height } = useWindowSize();

  const content = (
    <SeparationRow>
      <Col xs={12}>
        <FullyCentered>
          {_.times(count, key => (
            <Spinner key={key} variant="success" animation="grow" {...rest} /> // eslint-disable-line react/jsx-props-no-spreading
          ))}
        </FullyCentered>
      </Col>
      <Col>
        <FullyCentered>
          <i>
            <Text>(loading)</Text>
          </i>
        </FullyCentered>
      </Col>
    </SeparationRow>
  );

  return (
    <Fixed top={Math.round(height / 2)} width={width}>
      {content}
    </Fixed>
  );
}

const LoadingContext = createContext();

export function StatefulLoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  const contextValue = { loading, startLoading, stopLoading };

  return (
    <LoadingContext.Provider value={contextValue}>
      {loading && (
        <ReadOnlyContainer readOnly={true}>
          <Loader size="lg" />
        </ReadOnlyContainer>
      )}
      {children}
    </LoadingContext.Provider>
  );
}

export const useLoadingContext = () => useContext(LoadingContext);

export default Loader;
