import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { isNilOrEmpty } from '../../../../utils/object';
import {
  VerticallyCenteredLeftAlignedCell,
  VerticallyCenteredRightAlignedCell,
} from '../../../../components/common/TableLayout';

const NumericRenderer = ({ value, format, cell, options }) => {
  const { rightAligned = true } = options || {};
  const Wrapper = rightAligned
    ? VerticallyCenteredRightAlignedCell
    : VerticallyCenteredLeftAlignedCell;
  return (
    <Wrapper>
      {isNilOrEmpty(value) ? '' : cell.error ? value : numeral(value).format(format)}
    </Wrapper>
  );
};

NumericRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.object,
  format: PropTypes.string,
};

NumericRenderer.defaultProps = {
  value: null,
  format: '0,0',
  options: {},
};

export default NumericRenderer;
