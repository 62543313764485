import React from 'react';
import styled from 'styled-components';
import {
  Accordion as BootstrapAccordion,
  Card as BootstrapCard,
  Form as BootstrapForm,
  useAccordionToggle,
} from 'react-bootstrap';
import { DownIcon } from './BlackBearEnergy';

const Accordion = styled(BootstrapAccordion)`
  border-radius: 0;

  // Prevent overflowing date-picker from being cut
  & > .card {
    overflow: visible;
  }
`;

const Card = styled(BootstrapCard)`
  border-radius: 0;
  margin-bottom: 10px;
`;

const Header = styled(BootstrapCard.Header)`
  padding: 0.35rem 1.25rem;
  font-size: 1.5rem;
  font-weight: 500;
`;

const Body = styled(BootstrapCard.Body)`
  position: relative;
`;

const ErrorCount = styled.span`
  float: right;
  background-color: #ff4339;
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 60%;
  color: white;
  font-size: 1.1rem;
  margin-right: 9px;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px red;
`;

Card.Header = ({ title, errorCount, eventKey, onToggle }) => {
  const onClick = useAccordionToggle(eventKey, onToggle); // eslint-disable-line
  /* eslint-disable jsx-a11y/anchor-is-valid */
  return (
    <Header data-test-type="expandCollapseHeader" onClick={onClick}>
      <a>{title}</a>
      <DownIcon />
      {!!errorCount && <ErrorCount>{errorCount}</ErrorCount>}
    </Header>
  );
  /* eslint-enable jsx-a11y/anchor-is-valid */
};

Card.Body = Body;

const Form = styled(BootstrapForm)`
  border-radius: 0;
`;

export { Accordion, Card, Form };
