import _ from 'lodash';
import { getRendererByFieldType } from '../renderers/getByType';
import HeaderRenderer from '../renderers/HeaderRenderer';

function fieldsToHeader(fields) {
  return fields.map(key => ({
    value: key,
    valueViewer: HeaderRenderer,
    readOnly: true,
  }));
}

function recordToRow(record, fields) {
  return fields.map(key => ({
    value: _.get(record, [key, 'value'], ''),
    valueViewer: getRendererByFieldType({ fieldType: { value: _.get(record, [key, 'type']) } }),
    readOnly: true,
  }));
}

export const valueRenderer = cell => _.get(cell, 'value');

export function toDataSheetRows(questions) {
  const fields = ['question', 'answer', 'submissionDate'];
  const content = _.chain(questions)
    .compact()
    .map(record => recordToRow(record, fields))
    .value();
  return _.concat(
    [
      // HEADER
      fieldsToHeader(['Question', 'Answer', 'Submission Date']),
    ],
    // CONTENT
    content,
  );
}
