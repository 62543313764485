import _ from 'lodash';
import {
  RELATED_CUSTOMER_RFP_FIELD,
  RELATED_SCENARIO_FIELD,
  RELATED_SITE_RFP_FIELD,
} from '../containers/RFP/components/transformers/specialFields';

function optInDataChecks(optIn) {
  if (!_.get(optIn, [RELATED_CUSTOMER_RFP_FIELD, 'value'])) {
    console.warn(`Opt In with ID ${optIn.id} is missing the ${RELATED_CUSTOMER_RFP_FIELD} field.`);
  }
}

function sitesDataChecks(sites, siteScenarios) {
  const indexedSiteScenarios = _.groupBy(
    siteScenarios,
    _.property(`${RELATED_SITE_RFP_FIELD}.value`),
  );
  const missingSiteScenarios = _.filter(sites, site =>
    _.isEmpty(indexedSiteScenarios[_.get(site, 'id')]),
  );
  if (!_.isEmpty(missingSiteScenarios)) {
    console.warn(
      `Sites with IDs ${missingSiteScenarios
        .map(_.property('id'))
        .join(', ')} have no matching site scenarios.`,
    );
  }
}

function scenariosDataChecks(scenarios, siteScenarios) {
  const indexedSiteScenarios = _.groupBy(
    siteScenarios,
    _.property(`${RELATED_SCENARIO_FIELD}.value`),
  );
  const missingSiteScenarios = _.filter(scenarios, scenario =>
    _.isEmpty(indexedSiteScenarios[_.get(scenario, 'id')]),
  );
  if (!_.isEmpty(missingSiteScenarios)) {
    console.warn(
      `Scenarios with IDs ${missingSiteScenarios
        .map(_.property('id'))
        .join(', ')} have no matching site scenarios.`,
    );
  }
}

function siteScenariosDataChecks(siteScenarios, sites, scenarios) {
  const indexedSites = _.keyBy(sites, 'id');
  const indexedScenarios = _.keyBy(scenarios, 'id');

  const missingRelatedSite = _.filter(
    siteScenarios,
    siteScenario => !_.get(siteScenario, [RELATED_SITE_RFP_FIELD, 'value']),
  );
  const missingRelatedScenario = _.filter(
    siteScenarios,
    siteScenario => !_.get(siteScenario, [RELATED_SCENARIO_FIELD, 'value']),
  );
  const missingSite = _.filter(
    siteScenarios,
    siteScenario => !indexedSites[_.get(siteScenario, [RELATED_SITE_RFP_FIELD, 'value'])],
  );
  const missingScenario = _.filter(
    siteScenarios,
    siteScenario => !indexedScenarios[_.get(siteScenario, [RELATED_SCENARIO_FIELD, 'value'])],
  );
  if (!_.isEmpty(missingRelatedSite)) {
    console.warn(
      `Site Scenarios with IDs ${missingRelatedSite
        .map(_.property('id'))
        .join(', ')} are missing the ${RELATED_SITE_RFP_FIELD} field.`,
    );
  }
  if (!_.isEmpty(missingRelatedScenario)) {
    console.warn(
      `Site Scenarios with IDs ${missingRelatedScenario
        .map(_.property('id'))
        .join(', ')} are missing the ${RELATED_SCENARIO_FIELD} field.`,
    );
  }
  if (!_.isEmpty(missingSite)) {
    console.warn(
      `Site Scenarios with IDs ${missingSite
        .map(_.property('id'))
        .join(', ')} do not have a matching site.`,
    );
  }
  if (!_.isEmpty(missingScenario)) {
    console.warn(
      `Site Scenarios with IDs ${missingScenario
        .map(_.property('id'))
        .join(', ')} do not have a matching scenario.`,
    );
  }
}

export function rfpDataChecks({ optIn, sites, scenarios, siteScenarios }) {
  optInDataChecks(optIn);
  sitesDataChecks(sites, siteScenarios);
  scenariosDataChecks(scenarios, siteScenarios);
  siteScenariosDataChecks(siteScenarios, sites, scenarios);
}
