import _ from 'lodash';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class ActionTimer extends PureComponent {
  componentDidMount() {
    const { callImmediately, interval } = this.props;
    const intervalId = setInterval(this.intervalHandler.bind(this), interval);
    if (callImmediately) {
      this.intervalHandler();
    }
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    // Safeguard against null state
    const intervalId = _.get(this.state, 'intervalId');
    clearInterval(intervalId);
  }

  intervalHandler() {
    const { action } = this.props;
    action();
  }

  render() {
    return null;
  }
}

ActionTimer.propTypes = {
  action: PropTypes.func.isRequired,
  callImmediately: PropTypes.bool,
  interval: PropTypes.number,
};
ActionTimer.defaultProps = {
  callImmediately: false,
  interval: 30 * 1000 /* 30 SECONDS */,
};

export default ActionTimer;
