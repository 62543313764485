import _ from 'lodash';
import moment from 'moment';
import roundByType from './rounding';
import validate from '../validation';
import { DATE_FORMAT } from '../../constants';

export const stripSpacesAndCommas = val => val.trim().replace(/,/g, '');
export const stripDollarSign = val => val.replace(/^\$/, '');

export const formatCurrency = val => stripDollarSign(stripSpacesAndCommas(val));
export const formatDate = val => moment(val).format(DATE_FORMAT);
const formatNumeric = val => stripSpacesAndCommas(val);

export const formatByType = (val, type) => {
  if (/currency/.test(type)) {
    return formatCurrency(val);
  }
  if (/numeric/i.test(type)) {
    return formatNumeric(val);
  }
  if (type === 'Date' && moment(val).isValid()) {
    return formatDate(val);
  }
  return val;
};

const formatChanges = (collection, changes) =>
  _.map(changes, change => {
    const { cell, value } = change;
    const { recordId, fieldId, fieldName, fieldType } = cell;
    const siteScenario = _.find(collection, { id: Number(recordId) });
    // round before validating to ensure integers don't throw error
    const formattedValue = formatByType(value, fieldType);
    const roundedValue = roundByType(formattedValue, fieldType);
    const errorMessage = validate({ ...change, value: roundedValue });
    const updated = _.merge(
      { id: siteScenario.id },
      {
        [_.camelCase(fieldName)]: {
          fid: fieldId,
          userEdited: true,
          value: formattedValue,
          error: errorMessage,
        },
      },
    );
    return updated;
  });

export default formatChanges;
