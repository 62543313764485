import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactDataSheet from '@black-bear-energy/react-datasheet';
import './DataSheet.css';

function ReadOnlyEditor(data) {
  const { cell, onKeyDown } = data;
  const { valueViewer, value } = cell;
  return (
    <div className="read-only-container">
      {valueViewer(data)}
      <input
        className="cell read-only"
        type="text"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={value}
        onChange={() => {}}
        onKeyDown={function handleKeyDown(e) {
          e.persist();
          onKeyDown(e);
        }}
      />
    </div>
  );
}

export default function DataSheet(props) {
  const data = _.get(props, 'data').map(row =>
    row.map(cell => {
      const fixedCell = _.assign(cell);
      if (fixedCell.readOnly) {
        fixedCell.readOnly = false;
        fixedCell.className = 'read-only';
        fixedCell.dataEditor = ReadOnlyEditor;
      }
      return fixedCell;
    }),
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ReactDataSheet {...props} data={data} />;
}

DataSheet.propTypes = {
  data: PropTypes.array.isRequired,
};
