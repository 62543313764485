import _ from 'lodash';

// NOTE - We use this to see if any number of calls are loading
export const anyTrue = (...args) =>
  _.chain(args)
    .flattenDeep()
    .some()
    .value();

export const anyEmpty = (...args) => _.some(args, arg => _.isEmpty(arg));
