import _ from 'lodash';
import { withProps } from 'recompose';
import DateRenderer from './DateRenderer';
import FileRenderer from './FileRenderer';
import NumericRenderer from './NumericRenderer';
import MultiChoiceRenderer from './MultiChoiceRenderer';
import MultiChoiceReadOnlyRenderer from './MultiChoiceReadOnlyRenderer';
import DateReadOnlyRenderer from './DateReadOnlyRenderer';
import DefaultRenderer from './DefaultRenderer';
import UrlRenderer from './UrlRenderer';

const splitOptions = options => _.get(options, 'value', '').split(', ');

export const getRendererByFieldType = ({ fieldType, onClick }, options) => {
  const formattedType = _.toLower(_.get(fieldType, 'value'));
  switch (formattedType) {
    case 'text - multi-choice':
      return MultiChoiceReadOnlyRenderer;
    case 'timestamp':
    case 'date':
      return DateReadOnlyRenderer;
    case 'file':
      return withProps({ onClick })(FileRenderer);
    case 'numeric - integer':
      return withProps({ format: '0,0', options })(NumericRenderer);
    case 'numeric - decimal':
      return withProps({ format: '0,0.00', options })(NumericRenderer);
    case 'numeric - long decimal':
      return withProps({ format: '0,0.0000', options })(NumericRenderer);
    case 'numeric - integer currency':
      return withProps({ format: '$0,0', options })(NumericRenderer);
    case 'numeric - currency':
      return withProps({ format: '$0,0.00', options })(NumericRenderer);
    case 'numeric - long decimal currency':
      return withProps({ format: '$0,0.0000', options })(NumericRenderer);
    case 'numeric - percent':
      return withProps({ format: '0%', options })(NumericRenderer);
    case 'numeric - long percent':
      return withProps({ format: '0.00%', options })(NumericRenderer);
    case 'url':
      return withProps({ options })(UrlRenderer);
    default:
      return DefaultRenderer;
  }
};

export const getDataEditorByFieldType = ({ fieldType, multiChoiceOptions }) => {
  const formattedType = _.toLower(_.get(fieldType, 'value'));
  switch (formattedType) {
    case 'date':
      return DateRenderer;
    case 'file':
      return FileRenderer;
    case 'text - multi-choice':
      return withProps({ options: splitOptions(multiChoiceOptions) })(MultiChoiceRenderer);
    default:
      return undefined;
  }
};
