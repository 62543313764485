import _ from 'lodash';
import { RELATED_SCENARIO_FIELD } from '../transformers/specialFields';
import { getScenarioEnergyTechnology, isCustom, isSolar, isStorage } from './scenario';

export function filterSolarSiteScenarios(scenarios, siteScenarios) {
  const indexedScenarios = _.keyBy(scenarios, 'id');
  return _.filter(siteScenarios, siteScenario => {
    const scenario = indexedScenarios[_.get(siteScenario, [RELATED_SCENARIO_FIELD, 'value'])];
    return isSolar(getScenarioEnergyTechnology(scenario));
  });
}

export function filterStorageSiteScenarios(scenarios, siteScenarios) {
  const indexedScenarios = _.keyBy(scenarios, 'id');
  return _.filter(siteScenarios, siteScenario => {
    const scenario = indexedScenarios[_.get(siteScenario, [RELATED_SCENARIO_FIELD, 'value'])];
    return isStorage(getScenarioEnergyTechnology(scenario));
  });
}

export function filterCustomSiteScenarios(scenarios, siteScenarios) {
  const indexedScenarios = _.keyBy(scenarios, 'id');
  return _.filter(siteScenarios, siteScenario => {
    const scenario = indexedScenarios[_.get(siteScenario, [RELATED_SCENARIO_FIELD, 'value'])];
    return isCustom(getScenarioEnergyTechnology(scenario));
  });
}

export function filterAndMerge(
  siteScenariosBase,
  solarSiteScenarios,
  storageSiteScenarios,
  customSiteScenarios,
) {
  const indexedSolarSiteScenarios = _.keyBy(solarSiteScenarios, 'id');
  const indexedStorageSiteScenarios = _.keyBy(storageSiteScenarios, 'id');
  const indexedCustomSiteScenarios = _.keyBy(customSiteScenarios, 'id');
  return _.map(siteScenariosBase, siteScenario => {
    const solarSiteScenario = indexedSolarSiteScenarios[siteScenario.id];
    const storageSiteScenario = indexedStorageSiteScenarios[siteScenario.id];
    const customSiteScenario = indexedCustomSiteScenarios[siteScenario.id];
    return _.merge({}, solarSiteScenario, storageSiteScenario, customSiteScenario);
  });
}
