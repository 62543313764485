import React from 'react';
import PropTypes from 'prop-types';
import { VerticallyCenteredLeftAlignedCell } from '../../../../components/common/TableLayout';

const DefaultRenderer = ({ value }) => (
  <VerticallyCenteredLeftAlignedCell>{value}</VerticallyCenteredLeftAlignedCell>
);

DefaultRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DefaultRenderer.defaultProps = {
  value: null,
};

export default DefaultRenderer;
