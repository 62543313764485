import _ from 'lodash';
import { RELATED_SITE_RFP_FIELD } from '../transformers/specialFields';
import {
  filterSolarSiteScenarios,
  filterStorageSiteScenarios,
  filterCustomSiteScenarios,
  filterAndMerge,
} from './siteScenario';
import { getRelatedScenarioId, getRelatedSiteRfpId } from '../transformers/siteScenario';
import { SOLAR_ENERGY_TECHNOLOGY, STORAGE_ENERGY_TECHNOLOGY } from '../../constants';
import { containsSolar, containsStorage } from './scenario';

function ensureCascade(cascadingData, siteScenarios, customize) {
  if (!customize) {
    return _.map(siteScenarios, siteScenario => {
      const siteId = _.get(siteScenario, [RELATED_SITE_RFP_FIELD, 'value']);
      const cd = _.find(cascadingData, { id: siteId });
      return _.merge({}, siteScenario, _.omit(cd, ['id', 'bid']));
    });
  }
  return siteScenarios;
}

export function initializeDefaultSiteScenarios(savedOptIn, scenarios, siteScenarios) {
  const defaultSolarSiteScenarios = ensureCascade(
    _.get(savedOptIn, 'cascadingArrayDesignDetails'),
    filterSolarSiteScenarios(scenarios, siteScenarios),
    _.get(savedOptIn, 'view.customizeArrayDesignDetailsByScenario', false),
  );
  const defaultStorageSiteScenarios = ensureCascade(
    _.get(savedOptIn, 'cascadingStorageDesignDetails'),
    filterStorageSiteScenarios(scenarios, siteScenarios),
    _.get(savedOptIn, 'view.customizeStorageDesignDetailsByScenario', false),
  );
  const defaultCustomSiteScenarios = filterCustomSiteScenarios(scenarios, siteScenarios);
  return ensureCascade(
    _.get(savedOptIn, 'cascadingProjectSchedule'),
    filterAndMerge(
      siteScenarios,
      defaultSolarSiteScenarios,
      defaultStorageSiteScenarios,
      defaultCustomSiteScenarios,
    ),
    _.get(savedOptIn, 'view.customizeProjectScheduleByScenario', false),
  );
}

export function filterSitesForMatchingEnergyTechnology(
  sites,
  allScenarios,
  allSiteScenarios,
  energyTechnology,
) {
  const indexedBySite = _.groupBy(allSiteScenarios, getRelatedSiteRfpId);
  const scenariosIndexedById = _.keyBy(allScenarios, 'id');
  return _.filter(sites, ({ id }) => {
    const siteScenarios = indexedBySite[id];
    const scenarios = _.map(
      siteScenarios,
      siteScenario => scenariosIndexedById[getRelatedScenarioId(siteScenario)],
    );
    if (siteScenarios && siteScenarios.length) {
      if (energyTechnology === SOLAR_ENERGY_TECHNOLOGY) {
        return containsSolar(scenarios, siteScenarios);
      } else if (energyTechnology === STORAGE_ENERGY_TECHNOLOGY) {
        return containsStorage(scenarios, siteScenarios);
      } else {
        throw new Error(
          'This filter method should only be used with SOLAR_ENERGY_TECHNOLOGY or STORAGE_ENERGY_TECHNOLOGY.',
        );
      }
    } else {
      return false;
    }
  });
}
