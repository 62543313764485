import React from 'react';
import styled from 'styled-components';
import logo from './bbe_logo.jpg';
import title from './bbe_title.jpg';
import { useGetDeployment } from '../../services/deployment';

const HeaderContainer = styled.div`
  height: auto;
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
  margin-bottom: 40px;
`;

const LogoImg = styled.img`
  height: 4rem;
  margin: 0 1rem;
`;

const TitleImg = styled.img`
  height: 2.25rem;
`;

const Header = ({ children }) => {
  const { version, commit } = useGetDeployment();
  const versionString = `Version: ${version}\nCommit: ${commit}`;

  return (
    <HeaderContainer>
      <div>
        <LogoImg src={logo} alt={versionString} title={versionString} />
        <TitleImg src={title} alt="Black Bear Energy" />
      </div>
      {children}
    </HeaderContainer>
  );
};

export default Header;
