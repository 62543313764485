import _ from 'lodash';
import { useState } from 'react';
import { replaceByProperty } from '../utils/collection';
import { useAnyChangesSinceSave } from '../core/hooks/anyChanges';

export const useConfirmationsState = defaultConfirmations => {
  const [__, onChangesMade] = useAnyChangesSinceSave(); // eslint-disable-line no-unused-vars
  const transformedDefaultConfirmations = _.map(
    defaultConfirmations,
    ({ id, confirmationText, confirmation, sequence, dateCreated }) => ({
      id,
      confirmationText,
      sequence,
      dateCreated,
      confirmation: confirmation || /* DEFAULT VALUE */ {
        value: false,
      },
    }),
  );
  const [confirmations, setConfirmations] = useState(transformedDefaultConfirmations);

  // NOTE - Not in love with this defaulting, but do not have another great way
  const [defaultReceived, setDefaultReceived] = useState(false);
  if (!_.isEmpty(transformedDefaultConfirmations) && !defaultReceived) {
    setDefaultReceived(true);
    setConfirmations(transformedDefaultConfirmations);
  }

  const setSingleConfirmation = confirmation =>
    setConfirmations(replaceByProperty(confirmations, confirmation, 'id'));

  return [
    confirmations,
    confirmation => {
      setSingleConfirmation(confirmation);

      // NOTE - Signals unsaved changes to the central State Provider.
      onChangesMade();
    },
  ];
};
