import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import { Button } from '../../../../components/common/BlackBearEnergy';

const LightPadding = styled.div`
  padding: 5px;
`;

const LeftCol = styled(Col)`
  padding-right: 0;
`;

const RightCol = styled(Col)`
  padding-left: 0;
`;

const SmallerBlackBearEnergyButton = styled(Button)`
  line-height: 0.9em;
`;

const RelativeContainer = styled.div`
  position: relative;
`;

const MouseEventHandler = styled.div`
  z-index: 99999999;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

function BidButton({ children, disabled, onClick, className }) {
  const content = (
    <SmallerBlackBearEnergyButton
      className={className}
      size="sm"
      disabled={disabled}
      onClick={onClick}
      block
    >
      {children}
    </SmallerBlackBearEnergyButton>
  );
  if (disabled) {
    /* eslint-disable react/jsx-wrap-multilines */
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="disabled-until-customize">
            Please customize this energy type in order to bid.
          </Tooltip>
        }
      >
        <RelativeContainer>
          <MouseEventHandler />
          {content}
        </RelativeContainer>
      </OverlayTrigger>
    );
  } else {
    return content;
  }
  /* eslint-enable react/jsx-wrap-multilines */
}

export function BidRenderer({ cell, disabled }) {
  const { bid, onBid } = _.get(cell, 'siteScenario', {});
  return (
    <LightPadding>
      <Row>
        <LeftCol xs={6}>
          <BidButton className={bid ? 'on' : 'off'} disabled={disabled} onClick={onBid}>
            Bid
          </BidButton>
        </LeftCol>
        <RightCol xs={6}>
          <BidButton className={!bid ? 'on' : 'off'} disabled={disabled} onClick={onBid}>
            No Bid
          </BidButton>
        </RightCol>
      </Row>
    </LightPadding>
  );
}

BidRenderer.propTypes = {
  cell: PropTypes.object,
  disabled: PropTypes.bool,
};

BidRenderer.defaultProps = {
  cell: null,
  disabled: false,
};

export default BidRenderer;
