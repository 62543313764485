import qs from 'qs';
import _ from 'lodash';
import useAPI from '../core/hooks/useApi';
import api from './api';
import { useFileUploadProgress } from '../components/FileUpload';
import { useRFPQuery } from '../containers/RFP/hooks/query';

export const useGetBfQuestionsByOptInId = optInId =>
  useAPI(`/api/bf-questions?${qs.stringify({ optInId })}`);

/**
 * @return {function(questionId:number, acceptedFiles:File[]): Promise<S3FileResult[]>}
 */
export const useUploadFiles = () => {
  const [progressHandler, successHandler] = useFileUploadProgress();
  const { optInId, userId, token } = useRFPQuery();
  return async (id, acceptedFiles) => {
    const fileForm = new FormData();
    acceptedFiles.forEach(file => {
      fileForm.append('files', file);
    });

    // NOTE - We only accept one file at the moment.
    const file = acceptedFiles[0];

    // NOTE - We use this to capture metadata for file auditing.
    const query = qs.stringify({
      optInId,
      userId,
      token,
      bfQuestionId: id,
    });

    /**
     * @typedef S3FileResult {{
     *   fileKey: string,
     *   location: string,
     *   s3FileLink: string,
     *   fileName: string,
     * }}
     */
    const { data } = await api.put(`bf-questions/${id}/files?${query}`, fileForm, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: _.partial(progressHandler, file),
    });

    // NOTE - Just call when the data is retrieved
    successHandler(file);

    return data;
  };
};

/**
 * @return {function(questionId:number, fileName:string): Promise<{ deleted: boolean, id: number, fileName: string }>}
 */
export const useRemoveFiles = () => {
  const { optInId, userId, token } = useRFPQuery();
  return async (questionId, fileName) => {
    // NOTE - We use this to capture metadata for file auditing.
    const query = qs.stringify({
      optInId,
      userId,
      token,
      bfQuestionId: questionId,
    });

    const { data } = await api.delete(`bf-questions/${questionId}/files/${fileName}?${query}`);
    return data;
  };
};
