import qs from 'qs';
import axios from 'axios';
import useAPI from '../core/hooks/useApi';
import { useRFPQuery } from '../containers/RFP/hooks/query';

export const useGetRfpQuestions = (optInId, userId) =>
  useAPI(`/api/rfp-questions?${qs.stringify({ optInId, userId })}`);

export const useSubmitRfpQuestion = (optInId, userId) => {
  const { token } = useRFPQuery();
  return async question => {
    const response = await axios.request({
      url: `/api/rfp-questions?${qs.stringify({ optInId, userId, token })}`,
      method: 'POST',
      data: {
        question,
      },
    });
    return response;
  };
};
