import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';

const SectionWithTopMargin = styled.div`
  margin-top: 1rem;
`;

export function ErrorContainer({ errors }) {
  return (
    <SectionWithTopMargin>
      {errors.map((error, idx) => (
        <Alert variant="danger" key={idx}>
          {error}
        </Alert>
      ))}
    </SectionWithTopMargin>
  );
}

ErrorContainer.propTypes = {
  errors: PropTypes.array.isRequired,
};

ErrorContainer.defaultProps = {};

export default ErrorContainer;
