import qs from 'qs';
import _ from 'lodash';
import useAPI from '../core/hooks/useApi';
import api from './api';
import { useFileUploadProgress } from '../components/FileUpload';
import { useRFPQuery } from '../containers/RFP/hooks/query';
import { appendedFileName } from '../utils/file';
import { useNotifications } from '../core/hooks/notifications';

export const useGetSiteScenariosByOptInId = optInId =>
  useAPI(`/api/site-scenarios?${qs.stringify({ optInId })}`);

/**
 * @return {function(siteScenarioId:number, acceptedFiles:File[]): Promise<S3FileResult[]>}
 */
export const useUploadFiles = ({ cascading = false } = {}) => {
  const [progressHandler, successHandler] = useFileUploadProgress();
  const [sendNotification] = useNotifications();
  const { optInId, userId, token } = useRFPQuery();
  return async (id, acceptedFiles, appendToFilename = '') => {
    const fileForm = new FormData();
    acceptedFiles.forEach(file =>
      fileForm.append('files', file, appendedFileName(file.name, appendToFilename)),
    );

    // NOTE - We only accept one file at the moment.
    const file = acceptedFiles[0];

    // NOTE - We use this to capture metadata for file auditing.
    const query = qs.stringify({
      optInId,
      userId,
      token,
      [cascading ? 'siteId' : 'siteScenarioId']: id,
      cascading,
    });

    /**
     * @typedef S3FileResult {{
     *   fileKey: string,
     *   fileLink: string,
     *   s3FileLink: string,
     *   fileName: string,
     * }}
     */
    try {
      const { data } = await api.put(`site-scenarios/${id}/files?${query}`, fileForm, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: _.partial(progressHandler, file),
      });
      // NOTE - Just call when the data is retrieved
      successHandler(file);

      return data;
    } catch (err) {
      const message =
        err.response != null && err.response.data
          ? err.response.data.message || err.toString()
          : err.toString();
      sendNotification('danger', {
        title: 'File Upload failed',
        message: `${file.name} failed to upload, reason: ${message}`,
      });
      return null;
    }
  };
};

/**
 * @return {function(siteScenarioId:number, fileName:string): Promise<{ deleted: boolean, id: number, fileName: string }>}
 */
export const useRemoveFiles = ({ cascading = false } = {}) => {
  const { optInId, userId, token } = useRFPQuery();
  return async (id, fileName) => {
    // NOTE - We use this to capture metadata for file auditing.
    const query = qs.stringify({
      optInId,
      userId,
      token,
      [cascading ? 'siteId' : 'siteScenarioId']: id,
      cascading,
    });

    const { data } = await api.delete(`site-scenarios/${id}/files/${fileName}?${query}`);
    return data;
  };
};
