import React, { useCallback, useRef, useState } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { IoIosMenu, IoIosDownload, IoIosTrash } from 'react-icons/io';
import {
  Overlay,
  Popover as BootstrapPopover,
  ListGroup,
  Row,
  Col,
  Button,
  ButtonGroup,
} from 'react-bootstrap';
import { Z_THIRD_TIER } from '../../../components/common/Depth';

const WideInput = styled.input`
  padding: 0;
  margin: 0;
  line-height: 0.9em;
  width: 100%;
`;

const WideDiv = styled.div`
  margin: 0;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0;
  width: 100%;
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    background-color: #8f9499;
    opacity: 1;
  }
`;

const LightPadding = styled.div`
  padding: 0px;
`;

const LeftCol = styled(Col)`
  padding-right: 0;
`;

const RightCol = styled(Col)`
  padding-left: 0;
`;

const SquareButton = styled(Button)`
  border-radius: 0;
  width: 100%;
  padding: 2px 0px 6px 0px;
  margin: 0px;
  line-height: 0.9em;
`;

const CompactListItem = styled(ListGroup.Item)`
  padding: 8px;
`;

const DownloadIcon = styled(IoIosDownload)`
  margin-bottom: 6px;
`;

const TruncatedText = styled(WideDiv)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Popover = styled(BootstrapPopover)`
  z-index: ${Z_THIRD_TIER};
`;

const AttachmentsList = ({ files, onRemove }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const onClick = e => {
    setShow(!show);
    setTarget(e.target);
  };
  const handleOpen = fileLink => {
    window.open(fileLink, '_blank');
    setShow(false);
  };
  const handleRemove = fileName => {
    onRemove({ fileName });
    setShow(false);
  };
  return (
    <div ref={ref}>
      <SquareButton variant="outline-secondary" size="sm" onClick={onClick}>
        <IoIosMenu />
      </SquareButton>

      <Overlay placement="top" target={target} container={ref.current} show={show}>
        <Popover id="attachments-list">
          <Popover.Title>Files</Popover.Title>
          <Popover.Content>
            <ListGroup>
              {files.map(({ fileName, fileLink }, idx) => (
                <CompactListItem key={fileName || idx}>
                  <ButtonGroup aria-label="file actions">
                    <Button variant="info" onClick={_.partial(handleOpen, fileLink)}>
                      {fileName.toUpperCase()} <DownloadIcon />
                    </Button>
                    <Button variant="danger" onClick={_.partial(handleRemove, fileName)}>
                      <IoIosTrash />
                    </Button>
                  </ButtonGroup>
                </CompactListItem>
              ))}
            </ListGroup>
          </Popover.Content>
        </Popover>
      </Overlay>
    </div>
  );
};

/**
 * @param onDrop { function({ files: File[] }): void }
 * @param onRemove { function({ fileName: string }): void}
 * @param siteScenarioId { number }
 * @param files? { S3FileResult[] } - list of attached files
 */
const FileDrop = ({
  onDrop,
  onRemove,
  files = [],
  disabled = false,
  showAttachmentsList = true,
}) => {
  const onFilesDropped = useCallback(
    acceptedFiles => {
      onDrop({ files: acceptedFiles });
    },
    [onDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFilesDropped,
  });

  const firstFileName = _.get(files, [0, 'fileName']);

  function displayAttachButton() {
    return (
      <div {...getRootProps()}>
        <WideInput {...getInputProps()} disabled={disabled} />
        {isDragActive ? (
          <WideDiv className={`btn btn-sm btn-secondary ${disabled ? 'disabled' : ''}`}>
            {firstFileName || 'Drop'}
          </WideDiv>
        ) : (
          <WideDiv className={`btn btn-sm btn-secondary ${disabled ? 'disabled' : ''}`}>
            <TruncatedText>{firstFileName || 'Attach'}</TruncatedText>
          </WideDiv>
        )}
      </div>
    );
  }

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <>
      <LightPadding>
        {showAttachmentsList ? (
          <Row>
            <LeftCol xs={6}>{displayAttachButton()}</LeftCol>
            <RightCol xs={6}>
              <AttachmentsList files={files} onRemove={onRemove} />
            </RightCol>
          </Row>
        ) : (
          <Row>
            <Col xs={12}>{displayAttachButton()}</Col>
          </Row>
        )}
      </LightPadding>
    </>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

FileDrop.propTypes = {
  files: propTypes.array,
  onDrop: propTypes.func.isRequired,
  onRemove: propTypes.func.isRequired,
};

FileDrop.defaultProps = {
  files: [],
};

export default FileDrop;
