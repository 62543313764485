import _ from 'lodash';

export function replaceByProperty(collection, item, property = 'id') {
  const index = _.findIndex(collection, { id: _.get(item, property) });
  return _.concat(
    _.slice(collection, 0, index),
    item,
    _.slice(collection, index + 1, _.size(collection)),
  );
}

export function mergeSiteObjects(original, updated) {
  const merged = _.clone(original);
  // NOTE - _.toArray() here ensures that we have an array for the merge
  _.forEach(_.castArray(updated), item => {
    const index = _.findIndex(original, { id: _.get(item, 'id') });
    merged[index] = _.merge(original[index], item);
  });
  return merged;
}

export function mergeChanges(siteScenario, changes) {
  const merged = _.clone(siteScenario);
  _.forEach(changes, change => {
    const { cell, value } = change;
    const { fieldName, fieldId } = cell;
    _.merge(merged, {
      [_.camelCase(fieldName)]: {
        fid: fieldId,
        value,
        userEdited: true,
      },
    });
  });
  return merged;
}

export function mergeSavedAndDefaultCollections(
  savedCollection,
  defaultCollection,
  property = 'id',
) {
  const indexedSavedCollection = _.keyBy(savedCollection, property);
  return _.map(defaultCollection, item =>
    _.merge({}, item, indexedSavedCollection[_.get(item, property)]),
  );
}
