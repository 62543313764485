import _ from 'lodash';

export const assignGivenAndEditableToRow = row =>
  _.map(row, item => {
    // NOTE - Initialization for requested field values
    if (_.get(item, 'value') === '~') {
      return {
        ...item,
        userEdited: true,
        value: '',
        readOnly: false,
      };
    } else if (_.get(item, 'userEdited')) {
      return {
        ...item,
        readOnly: false,
      };
    } else {
      return {
        ...item,
        readOnly: true,
      };
    }
  });
