import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import '@black-bear-energy/react-datasheet/lib/react-datasheet.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style-overrides/cell.css';
import './style-overrides/cell-error.css';
import './style-overrides/date-picker.css';
import './style-overrides/danger-alert.css';
import { StateProvider } from './core/store';
import GlobalStyles from './components/GlobalStyles';
import Notifications, { NotificationsProvider } from './components/Notifications';
import RFP from './containers/RFP/RFP';
import Success from './containers/Success';
import NotAuthorized from './containers/NotAuthorized';
import FileUploadProvider from './components/FileUpload';
import { Session } from './containers/Session';

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Notifications />
      <Container fluid>
        <Session>
          <Route exact path="/" component={RFP} />
          <Route path="/success" component={Success} />
        </Session>
        <Route path="/not-authorized" component={NotAuthorized} />
      </Container>
    </>
  );
};

ReactDOM.render(
  <StateProvider>
    <NotificationsProvider>
      <FileUploadProvider>
        <Router>
          <App />
        </Router>
      </FileUploadProvider>
    </NotificationsProvider>
  </StateProvider>,
  document.getElementById('root'),
);
