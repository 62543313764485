import React from 'react';
import DataCellRenderer from './DataCellRenderer';
import { VerticallyHorizontallyCenteredCell } from '../../../../components/common/TableLayout';

/* eslint-disable react/jsx-props-no-spreading */
export const withFieldIdentifier = ({ fieldId }) => HOC => props => (
  <VerticallyHorizontallyCenteredCell data-test-type="dataSheetCell" data-field-id={fieldId}>
    {HOC ? <HOC {...props} /> : <DataCellRenderer {...props} />}
  </VerticallyHorizontallyCenteredCell>
);
/* eslint-enable react/jsx-props-no-spreading */
