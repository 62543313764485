import _ from 'lodash';
import qs from 'qs';
import uuid from 'uuid/v4';
import api from './api';
import useAPI from '../core/hooks/useApi';
import { useRFPQuery } from '../containers/RFP/hooks/query';

// NOTE - Not in love with having this here, but serves the purpose.
export const sessionId = uuid();

export const useGetSession = ({ optInId, userId, token }) =>
  useAPI(`/api/sessions?${qs.stringify({ optInId, userId, sessionId, token })}`);

export const useCreateOrUpdateSession = ({ optInId, userId }, transition = _.noop) => {
  const { token } = useRFPQuery();
  const onCreateOrUpdate = async () => {
    const response = await api.request({
      url: `/sessions?${qs.stringify({ token })}`,
      method: 'PUT',
      data: {
        optInId,
        userId,
        sessionId,
      },
    });
    await transition(_.get(response, 'data'));
    return response;
  };
  return [onCreateOrUpdate];
};
