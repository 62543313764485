import _, { memoize } from 'lodash';
import { getRendererByFieldType } from '../renderers/getByType';
import { removeEmptyColumns, matchHeaderToColumns } from './common';
import HeaderRenderer from '../renderers/HeaderRenderer';

const byOrder = (f1, f2) => _.get(f1, 'order', 0) - _.get(f2, 'order', 0);

function fieldsAndRecordToRow({ fields, record, onDownload }) {
  const indexedByFieldId = _.keyBy(record, 'fid');
  return _.chain(fields)
    .sort(byOrder) // Sort the fields by the order prop
    .map(field => {
      const fieldId = _.get(field, 'id');
      const value = _.get(indexedByFieldId, [fieldId, 'value'], '');
      const fieldName = _.get(indexedByFieldId, [fieldId, 'name'], '');
      const fieldType = _.get(field, 'field_type');
      const onClick = fieldType === 'file' ? _.partial(onDownload, record) : undefined;
      const valueViewer = getRendererByFieldType({ fieldType: { value: fieldType }, onClick });
      const rawCell = {
        recordId: _.get(record, 'id'),
        fieldId,
        fieldName,
        fieldType,
        readOnly: true,
        required: 0,
        value,
        valueViewer,
        userEdited: false,
      };

      // Remove undefined properties
      const clean = _.omitBy(rawCell, _.isUndefined);
      return clean;
    })
    .value();
}

export const fieldToHeader = ({ id, label }, width) => ({
  value: label,
  fieldId: id,
  readOnly: true,
  valueViewer: HeaderRenderer,
  width,
});

export const fieldsToHeader = memoize(({ fields, width }) =>
  _.chain(fields)
    .map(field => fieldToHeader(field, width))
    .value(),
);

export const valueRenderer = cell => _.get(cell, 'value');

export function toDataSheetRows({
  attachments,
  fields,
  onDownload,
  addUploadDocumentHeader = false,
}) {
  const content = removeEmptyColumns(
    _.chain(attachments)
      .compact()
      .map(record =>
        fieldsAndRecordToRow({
          fields,
          record,
          onDownload,
        }),
      )
      .value(),
  );
  return _.concat(
    [
      // HEADER
      [
        ...matchHeaderToColumns({
          header: fieldsToHeader({ fields }),
          content,
        }),
        ...(addUploadDocumentHeader ? [fieldToHeader({ label: 'Upload Document' })] : []), // Enabled in case of Upload Documents
      ],
    ],
    // CONTENT
    content,
  );
}

export function removeDownloadUploadField(fields) {
  return fields.filter(field => field.label !== 'Upload/Download');
}
