import _ from 'lodash';
import { useState } from 'react';
import { replaceByProperty } from '../utils/collection';
import { useAnyChangesSinceSave } from '../core/hooks/anyChanges';

export const useQuestionsState = defaultQuestions => {
  const [__, onChangesMade] = useAnyChangesSinceSave(); // eslint-disable-line no-unused-vars
  const transformedDefaultQuestions = _.map(
    defaultQuestions,
    ({ id, question, sequence, answer }) => ({
      id,
      question,
      sequence,
      answer: answer || /* DEFAULT VALUE */ {
        value: '',
      },
    }),
  );
  const [questions, setQuestions] = useState(transformedDefaultQuestions);

  // NOTE - Not in love with this defaulting, but do not have another great way
  const [defaultReceived, setDefaultReceived] = useState(false);
  if (!_.isEmpty(transformedDefaultQuestions) && !defaultReceived) {
    setDefaultReceived(true);
    setQuestions(transformedDefaultQuestions);
  }

  const setSingleQuestion = question => {
    setQuestions(replaceByProperty(questions, question, 'id'));

    // NOTE - Signals unsaved changes to the central State Provider.
    onChangesMade();
  };

  return [questions, setSingleQuestion];
};
