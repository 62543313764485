import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import ExpandCollapse from '../../../components/common/ExpandCollapse';
import { Form } from '../../../components/common/BootstrapOverrides';
import { useBBEContext } from '../../../core/store';
import { TOGGLE_EXPAND_COLLAPSE } from '../../../core/actions';
import { expandCollapseByTitleSelector } from '../../../core/selectors';
import { BBE_PINK } from '../../../components/common/BlackBearEnergy';
import { ReadOnlyContainer } from '../../../components/common/Interaction';

const ClickableTableRow = styled.tr`
  cursor: pointer;
`;

const HoverTableCell = styled.td`
  &:hover {
    color: ${BBE_PINK};
  }
`;

export function Confirmations({ confirmations, locked, onChangeConfirmation }) {
  const onChange = confirmation =>
    onChangeConfirmation({
      ...confirmation,
      confirmation: {
        value: !_.get(confirmation, 'confirmation.value'),
      },
    });
  const { dispatch, state } = useBBEContext();
  const title = 'Confirmations';
  return (
    <ExpandCollapse
      title={title}
      openByDefault={expandCollapseByTitleSelector(state, title)}
      onToggle={() =>
        dispatch({
          type: TOGGLE_EXPAND_COLLAPSE,
          payload: title,
        })
      }
    >
      <ReadOnlyContainer readOnly={locked}>
        {/* eslint-disable react/no-unknown-property */}
        <table borderless="true">
          {/* eslint-enable react/no-unknown-property */}
          <tbody>
            {_.chain(confirmations)
              .sortBy(['sequence.value', 'dateCreated.value'])
              .map(confirmation => {
                const id = _.get(confirmation, 'id');
                const confirmationText = _.get(confirmation, 'confirmationText');
                const checked = _.get(confirmation, 'confirmation.value');
                return (
                  <ClickableTableRow
                    key={id}
                    data-test-type="confirmationRow"
                    onClick={_.partial(onChange, confirmation)}
                  >
                    <td>
                      <Form.Check type="checkbox" checked={checked} onChange={_.noop} />
                    </td>
                    <HoverTableCell>{_.get(confirmationText, 'value')}</HoverTableCell>
                  </ClickableTableRow>
                );
              })
              .value()}
          </tbody>
        </table>
      </ReadOnlyContainer>
    </ExpandCollapse>
  );
}

Confirmations.propTypes = {
  confirmations: PropTypes.array,
  locked: PropTypes.bool,
  onChangeConfirmation: PropTypes.func.isRequired,
};

Confirmations.defaultProps = {
  confirmations: null,
  locked: false,
};

export default Confirmations;
