import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { TextCenter, TopCover } from '../../../components/common/Layout';

export function CannotLoadMessage({ message }) {
  return (
    <TopCover>
      <Row>
        <Col xs={12}>
          <TextCenter>
            <h4>{message}</h4>
          </TextCenter>
        </Col>
      </Row>
    </TopCover>
  );
}

export default CannotLoadMessage;
