import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

const LeftCol = styled(Col)`
  margin-top: 5px;
  padding-right: 5px;
`;

const RightCol = styled(Col)`
  margin-top: 5px;
  padding-left: 5px;
`;

export function Pagination({ onNext, onLast, lastDisabled, nextDisabled }) {
  return (
    <Row>
      <LeftCol>
        <Button size="md" variant="success" disabled={lastDisabled} onClick={onLast} block>
          Previous
        </Button>
      </LeftCol>
      <RightCol>
        <Button size="md" variant="success" disabled={nextDisabled} onClick={onNext} block>
          Next
        </Button>
      </RightCol>
    </Row>
  );
}

Pagination.propTypes = {
  onNext: PropTypes.func.isRequired,
  onLast: PropTypes.func.isRequired,
  lastDisabled: PropTypes.bool.isRequired,
  nextDisabled: PropTypes.bool.isRequired,
};

export default Pagination;
