import React, { useRef } from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { HamburgerArrow } from 'react-animated-burgers';
import { BBE_PINK } from './BlackBearEnergy';

const BurgerContainer = styled.div`
  float: right;

  button {
    &:focus {
      outline: none;
    }
  }
`;

const BlackBearHamburgerArrow = styled(HamburgerArrow)`
  float: right;
  margin-top: -8px;

  span {
    background-color: ${BBE_PINK};

    &:before,
    &:after {
      background-color: ${BBE_PINK};
    }
  }
`;

const Menu = styled.div`
  ul {
    list-style: none;
  }

  a {
    cursor: pointer;
    display: block;
    float: left;
    color: ${BBE_PINK};
    padding-bottom: 15px;
  }
`;

function BurgerMenu({ children }) {
  const ref = useRef();
  const hide = () => ref.current.hide();

  const menu = (
    <Popover>
      <Popover.Content>
        <Menu>{children({ hide })}</Menu>
      </Popover.Content>
    </Popover>
  );
  return (
    <BurgerContainer>
      <OverlayTrigger overlay={menu} trigger="click" placement="left" ref={ref}>
        <BlackBearHamburgerArrow />
      </OverlayTrigger>
    </BurgerContainer>
  );
}

export default BurgerMenu;
