import React, { useLayoutEffect, useRef, useState } from 'react';
import _ from 'lodash';

function MeasuredContainer({ children }) {
  const ref = useRef(null);
  const [widthHeight, setWidthHeight] = useState({
    width: 0,
    height: 0,
  });
  useLayoutEffect(
    () =>
      setWidthHeight({
        width: _.get(ref, 'current.offsetWidth'),
        height: _.get(ref, 'current.offsetHeight'),
      }),
    [],
  );
  return <div ref={ref}>{children(widthHeight)}</div>;
}

export default MeasuredContainer;
