import React from 'react';
import { ButtonLink } from '../../../../components/common/BlackBearEnergy';

const FileRenderer = ({ value, onClick }) => (
  <ButtonLink onClick={onClick} block variant="link" disabled={value.disabled}>
    {value.filename}
  </ButtonLink>
);

export default FileRenderer;
