import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const styles = {
  container: provided => ({
    ...provided,
    width: '100%',
    height: '32px',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 0 0 10px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '100%',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'inherit',
  }),
  control: provided => ({
    ...provided,
    minHeight: 'unset',
    height: '100%',
    border: 'none',
    backgroundColor: 'transparent',
  }),
  placeholder: () => ({
    color: 'inherit',
  }),
};

// readOnly prop is passed to apply appropriate styling for bid/no-bid
const MultiChoiceReadOnlyRenderer = ({ value }) => {
  // Disabled to prevent dropdown when not in edit mode (for compatibility with react-datasheet)
  // Styled as if enabled to let the user know it's ok to edit value.
  return <Select isDisabled styles={styles} placeholder={value} />;
};

MultiChoiceReadOnlyRenderer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MultiChoiceReadOnlyRenderer.defaultProps = {
  value: null,
};

export default MultiChoiceReadOnlyRenderer;
