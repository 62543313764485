import React, { Component, createContext, useContext } from 'react';
import _ from 'lodash';

export const FileUploadContext = createContext();

export const useFileUploadProgress = () => {
  const { createUploadProgressHandler, createUploadSuccessHandler } = useContext(FileUploadContext);
  return [createUploadProgressHandler(), createUploadSuccessHandler()];
};

/* eslint-disable react/no-unused-state */
export default class FileUploadProvider extends Component {
  constructor() {
    super();
    this.state = {
      files: null,
      createUploadProgressHandler: this.createUploadProgressHandler,
      createUploadSuccessHandler: this.createUploadSuccessHandler,
    };
  }

  createUploadProgressHandler = () => (file, progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.setState(prevState => ({
      files: {
        ...prevState.files,
        [file.name]: percentCompleted,
      },
    }));
  };

  createUploadSuccessHandler = () => file =>
    this.setState(prevState => ({
      files: _.omit(prevState.files, file.name),
    }));

  render() {
    const { children } = this.props;
    return <FileUploadContext.Provider value={this.state}>{children}</FileUploadContext.Provider>;
  }
}
/* eslint-enable react/no-unused-state */
