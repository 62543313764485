import React, { createContext, useReducer, useContext } from 'react';
import _ from 'lodash';
import {
  UPDATE_RECENTLY_SAVED_OPT_IN,
  ANY_CHANGE_MADE_SINCE_SAVE,
  TOGGLE_EXPAND_COLLAPSE,
  DEFAULT_EXPAND_COLLAPSE,
  NEWER_SESSION_REGISTERED,
  CLEAR_ANY_CHANGE_MADE_SINCE_SAVE,
} from './actions';

const initialState = {};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, { type, payload }) => {
    switch (type) {
      case UPDATE_RECENTLY_SAVED_OPT_IN:
        return {
          ...state,
          anyChanges: false,
          recentlySavedOptIn: payload,
          saveCount: (state.saves || 0) + 1,
        };
      case ANY_CHANGE_MADE_SINCE_SAVE:
        return {
          ...state,
          anyChanges: true,
        };
      case CLEAR_ANY_CHANGE_MADE_SINCE_SAVE:
        return {
          ...state,
          anyChanges: false,
        };
      case TOGGLE_EXPAND_COLLAPSE:
        const key = _.camelCase(payload);
        return {
          ...state,
          expandedSections: {
            ...state.expandedSections,
            [key]: !_.get(state, ['expandedSections', key]),
          },
        };
      case DEFAULT_EXPAND_COLLAPSE:
        return {
          ...state,
          expandedSections: payload || {},
        };
      case NEWER_SESSION_REGISTERED:
        return {
          ...state,
          newerSessionRegistered: true,
        };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const useBBEContext = () => useContext(store);
const useBBEState = () => useBBEContext().state;
const useBBEDispatch = () => useBBEContext().dispatch;

export { store, StateProvider, useBBEContext, useBBEState, useBBEDispatch };
