import React, { Component, createContext, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'lodash';
import { Button } from './common/BlackBearEnergy';
import { NewLineParagraph } from './common/Layout';

export const NotificationsContext = createContext({
  notification: null,
  metadata: null,
  setMetadata: _.noop,
  sendNotification: _.noop,
  sendConfirmation: _.noop,
  clearNotification: _.noop,
});

/* eslint-disable react/no-unused-state */
export class NotificationsProvider extends Component {
  constructor() {
    super();
    this.state = {
      notification: null,
      setMetadata: this.setMetadata,
      sendNotification: this.sendNotification,
      sendConfirmation: this.sendConfirmation,
      clearNotification: this.clearNotification,
    };
  }

  sendNotification = (type, { title, message } = {}) =>
    this.setState({
      notification: {
        type,
        title,
        message,
      },
    });

  sendConfirmation = (
    type,
    { title, message, onOk, onCancel = _.noop, okLabel = 'OK', cancelLabel = 'Cancel' },
  ) =>
    this.setState({
      notification: {
        confirmation: true,
        type,
        title,
        message,
        onOk,
        onCancel,
        okLabel,
        cancelLabel,
      },
    });

  setMetadata = metadata => {
    this.setState({ metadata });
  };

  clearNotification = () =>
    this.setState({
      notification: null,
      metadata: null,
    });

  render() {
    const { children } = this.props;
    return (
      <NotificationsContext.Provider value={this.state}>{children}</NotificationsContext.Provider>
    );
  }
}
/* eslint-enable react/no-unused-state */

function FooterButtons({
  confirmation,
  onOk,
  onCancel,
  clearNotification,
  okLabel = 'OK',
  cancelLabel = 'Cancel',
  variant = 'success',
}) {
  if (confirmation) {
    return (
      <>
        <Button variant={variant} onClick={_.flow(onOk || _.noop, clearNotification)}>
          {okLabel}
        </Button>
        <Button variant="light" onClick={_.flow(onCancel || _.noop, clearNotification)}>
          {cancelLabel}
        </Button>
      </>
    );
  } else {
    return (
      <Button variant={variant} onClick={clearNotification}>
        {okLabel}
      </Button>
    );
  }
}

export function Notifications() {
  const { notification, metadata, clearNotification } = useContext(NotificationsContext);
  const message = _.get(notification, 'message');

  return (
    <Modal show={!!message} centered>
      <Modal.Body>
        <h4>{_.get(notification, 'title', 'Notice')}</h4>
        <NewLineParagraph>{message}</NewLineParagraph>
      </Modal.Body>
      <Modal.Footer>
        <FooterButtons
          confirmation={_.get(notification, 'confirmation')}
          onOk={_.partial(_.get(notification, 'onOk', _.noop), metadata)}
          onCancel={_.partial(_.get(notification, 'onCancel', _.noop), metadata)}
          clearNotification={clearNotification}
          okLabel={_.get(notification, 'okLabel')}
          cancelLabel={_.get(notification, 'cancelLabel')}
          variant={_.get(notification, 'type')}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default Notifications;
