import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withProps } from 'recompose';
import { PROPERTY_NAME_FIELD } from './transformers/specialFields';
import { mergeSiteObjects } from '../../../utils/collection';
import { ANY_CHANGE_MADE_SINCE_SAVE } from '../../../core/actions';
import formatChanges from './helpers/formatting';
import { transformFieldLabel } from './transformers/common';
import { isValidFieldChange } from './helpers/field';

export const ATTACHMENT_PROPERTY_TO_FIELD_NAME_MAPPING = {
  arrayLayout: 'Array Layout',
  storageDesign: 'Storage Design',
};

function formatDefaultSites({ defaultSites, bidKey, includeBid }) {
  return _.chain(defaultSites)
    .map(site => {
      const bidObj = includeBid
        ? { [bidKey]: { value: _.get(site, [bidKey, 'value'], true) } }
        : {};
      return {
        ..._.pickBy(
          site,
          (v, k) =>
            _.includes(['id', 'siteRfpName', 'propertyName', PROPERTY_NAME_FIELD], k) ||
            _.get(v, 'userEdited'),
        ),
        ...bidObj,
      };
    })
    .value();
}

// NOTE - We are retrofitting this to the cell interface. Not the prettiest, but something we need for stability at the moment.
export function filesToCellChange(id, fieldName, files) {
  return [
    {
      cell: {
        recordId: id,
        fieldType: 'File',
        fieldName,
      },
      value: files,
    },
  ];
}

export function siteToCellChanges({
  site,
  fields,
  includeArrayLayout = false,
  includeStorageDesign = false,
}) {
  const { id } = site;
  const indexedFields = _.keyBy(fields, field =>
    transformFieldLabel(_.get(field, 'fieldName.value')),
  );
  const mappedSiteCellChanges = _.chain(indexedFields)
    .toPairs()
    .reject(([k]) => _.includes(['id', 'bid', 'propertyName', 'propertyNameObsf'], k))
    .map(([k, v]) => ({
      cell: {
        recordId: id,
        fieldName: _.get(v, 'fieldName.value'),
      },
      value: _.get(site, [k, 'value'], null),
    }))
    .value();
  if (includeArrayLayout) {
    return mappedSiteCellChanges.concat(
      filesToCellChange(
        id,
        ATTACHMENT_PROPERTY_TO_FIELD_NAME_MAPPING.arrayLayout,
        _.get(site, 'arrayLayout.value', null),
      ),
    );
  } else if (includeStorageDesign) {
    return mappedSiteCellChanges.concat(
      filesToCellChange(
        id,
        ATTACHMENT_PROPERTY_TO_FIELD_NAME_MAPPING.storageDesign,
        _.get(site, 'storageDesign.value', null),
      ),
    );
  }
  return mappedSiteCellChanges;
}

export function validChangesOnly(changes, fields) {
  if (_.isArray(changes)) {
    return _.map(changes, change => validChangesOnly(change, fields));
  } else {
    const indexedFields = _.keyBy(fields, v => _.camelCase(_.get(v, 'fieldName.value')));
    return _.pickBy(changes, (v, k) => {
      const field = indexedFields[k];
      return !field || !_.get(v, 'value') || isValidFieldChange(_.get(v, 'value'), field);
    });
  }
}

function createCascadingSiteContext() {
  return createContext({
    sites: null,
    customizeByScenario: false,
    setDefaultSites: _.noop,
    setSiteOnCellChange: _.noop,
    setFilesForUpload: _.noop,
    setCustomizeByScenario: _.noop,
  });
}

/* eslint-disable react/no-unused-state */
class CascadingSiteProvider extends Component {
  constructor() {
    super();
    this.state = {
      sites: null,
      defaultsReceived: false,
      customizeByScenario: false,
      setDefaultSites: this.setDefaultSites,
      setSiteOnCellChange: this.setSiteOnCellChange,
      setFilesForUpload: this.setFilesForUpload,
      setCustomizeByScenario: this.setCustomizeByScenario,
    };
  }

  setDefaultSites = (defaultSites, defaultCustomize) => {
    const { defaultsReceived } = this.state;
    if (!_.isEmpty(defaultSites) && !defaultsReceived) {
      const { includeBid, bidKey } = this.props;
      const formattedDefaultSites = formatDefaultSites({
        defaultSites,
        includeBid,
        bidKey,
      });
      this.setState({
        sites: formattedDefaultSites,
        customizeByScenario: defaultCustomize,
        defaultsReceived: true,
      });
    }
  };

  setSiteOnCellChange = changes => {
    const { fields } = this.props;
    const validChanges = validChangesOnly(changes, fields);
    this.setState(prevState => {
      const sites = mergeSiteObjects(prevState.sites, validChanges);
      return {
        sites,
      };
    });

    // NOTE - Signals unsaved changes to the central State Provider.
    this.onChangesMade();
  };

  setCustomizeByScenario = ({
    customizeByScenario,
    energyTechnology,
    onReCascadeAllChanges = _.noop,
  }) => {
    const { fields, includeArrayLayout, includeStorageDesign } = this.props;
    const { sites } = this.state;
    this.setState({ customizeByScenario });

    // NOTE - Resets the state to the cascaded values
    if (!customizeByScenario) {
      _.each(sites, site =>
        onReCascadeAllChanges(
          siteToCellChanges({
            site,
            fields,
            includeArrayLayout,
            includeStorageDesign,
          }),
          energyTechnology,
        ),
      );
    }

    // NOTE - Signals unsaved changes to the central State Provider.
    this.onChangesMade();
  };

  // NOTE - We separated this so the function could be sync, and manage the state accordingly. We don't await when called, just fire and forget.
  setFilesForUpload = async ({
    id,
    files,
    energyTechnology,
    fileProperty,
    onCascadeChanges = _.noop,
  }) => {
    // NOTE - Don't love this coming from props, but wanted stability for now.
    const { onUpload } = this.props;
    const data = await onUpload(id, files, _.kebabCase(fileProperty));

    if (data != null) {
      // if there wasn't an error.
      const fieldName = ATTACHMENT_PROPERTY_TO_FIELD_NAME_MAPPING[fileProperty];
      const updated = filesToCellChange(id, fieldName, data.files);
      // NOTE - Not a fan of having this format method here. But we need to unwind some of this.
      const { sites } = this.state;
      this.setSiteOnCellChange(formatChanges(sites, updated));
      // NOTE - This method still comes from the state hooks.
      onCascadeChanges(updated, energyTechnology);

      // NOTE - Signals unsaved changes to the central State Provider.
      this.onChangesMade();
    }
  };

  onChangesMade = () => {
    // NOTE - Don't love this coming from props, but wanted stability for now.
    const { dispatch } = this.props;
    dispatch({ type: ANY_CHANGE_MADE_SINCE_SAVE });
  };

  render() {
    const { context, children } = this.props;
    const { Provider } = context;
    return <Provider value={this.state}>{children}</Provider>;
  }
}
/* eslint-enable react/no-unused-state */

CascadingSiteProvider.propTypes = {
  includeBid: PropTypes.bool,
  includeArrayLayout: PropTypes.bool,
  includeStorageDesign: PropTypes.bool,
  bidKey: PropTypes.string,
  context: PropTypes.object.isRequired,
};
CascadingSiteProvider.defaultProps = {
  includeBid: true,
  includeArrayLayout: false,
  includeStorageDesign: false,
  bidKey: 'bid',
};

export const CascadingArrayDesignContext = createCascadingSiteContext();
export const CascadingStorageDesignContext = createCascadingSiteContext();
export const CascadingProjectScheduleContext = createCascadingSiteContext();

export const CascadingArrayDesignProvider = withProps({
  includeBid: true,
  includeArrayLayout: true,
  context: CascadingArrayDesignContext,
})(CascadingSiteProvider);
export const CascadingStorageDesignProvider = withProps({
  includeBid: true,
  includeStorageDesign: true,
  context: CascadingStorageDesignContext,
})(CascadingSiteProvider);
export const CascadingProjectScheduleProvider = withProps({
  includeBid: true,
  context: CascadingProjectScheduleContext,
})(CascadingSiteProvider);
