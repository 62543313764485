import _ from 'lodash';
import { isNilOrEmpty } from '../../../../utils/object';

// NOTE - This check only matters for fields that have no value. This is only to be used in the submission checking case.
export const isEmptyReadOnlyForSubmission = ({ dataType, custom, value }) => {
  const lowerDataType = _.toLower(dataType);
  const isGivenWithNoValue =
    lowerDataType === 'given' || (/give/i.test(lowerDataType) && value !== '~');
  return isNilOrEmpty(value) && (isGivenWithNoValue || custom);
};
