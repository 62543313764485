import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import DataSheet from '../../../components/common/DataSheet';
import { SeparationRow } from '../../../components/common/Layout';
import { HorizontalScroller } from '../../../components/common/Scrolling';
import { useGetFieldThresholds } from '../../../services/fieldThreshold';
import {
  toDataSheetRows as siteScenariosToDataSheetRows,
  valueRenderer as siteScenarioValueRenderer,
  distributeColumns as distributeSiteScenarioColumns,
} from './transformers/siteScenario';
import {
  toDataSheetRows as scenarioToDataSheetRows,
  valueRenderer as scenarioValueRenderer,
} from './transformers/scenario';
import { cellsChangedHandler, bidHandler, uploadHandler } from './helpers/tableHandlers';
import {
  getScenarioName,
  getScenarioEnergyTechnology,
  isSolar,
  isStorage,
} from './helpers/scenario';
import CascadingSiteDataSheet from './CascadingSiteDataSheet';
import DataCellRenderer from './renderers/DataCellRenderer';
import ExpandCollapse from '../../../components/common/ExpandCollapse';
import MeasuredContainer from '../../../components/common/MeasuredContainer';
import { parseErrors, countErrors } from './helpers/errors';
import ErrorContainer from '../../Error/ErrorContainer';
import { useBBEContext } from '../../../core/store';
import { TOGGLE_EXPAND_COLLAPSE } from '../../../core/actions';
import { expandCollapseByTitleSelector } from '../../../core/selectors';
import { ReadOnlyContainer } from '../../../components/common/Interaction';
import { useUploadFiles } from '../../../services/siteScenario';
import { useSiteScenarioWidthEstimate } from './hooks/siteScenario';

const LightMargin = styled.div`
  margin-bottom: 10px;
`;

function ScenarioGivenFields({ scenario, fields }) {
  return (
    <MeasuredContainer>
      {({ width }) => (
        <DataSheet
          className="rfpDataSheet"
          data={scenarioToDataSheetRows({
            scenario,
            fields,
            width,
          })}
          valueRenderer={scenarioValueRenderer}
          valueViewer={DataCellRenderer}
        />
      )}
    </MeasuredContainer>
  );
}

function SiteScenarioFields({
  sites,
  scenario,
  siteScenarios,
  fields,
  disableBid,
  onBid,
  onCellsChanged,
  setErrorCount = _.noop,
}) {
  const { data: fieldThresholds } = useGetFieldThresholds() || {};
  const data = siteScenariosToDataSheetRows({
    sites,
    scenario,
    siteScenarios,
    fields,
    fieldThresholds,
    disableBid,
    onBid,
    horizontalScroll: true,
  });
  const errors = parseErrors(data);
  setErrorCount(countErrors(data));

  const width = useSiteScenarioWidthEstimate();
  const dataWithDistributedColumns = distributeSiteScenarioColumns({
    width,
    data,
  });

  return (
    <>
      <Row>
        <Col xs={12}>
          <HorizontalScroller>
            <LightMargin>
              <DataSheet
                className="rfpDataSheet"
                data={dataWithDistributedColumns}
                valueRenderer={siteScenarioValueRenderer}
                valueViewer={DataCellRenderer}
                onCellsChanged={onCellsChanged}
              />
            </LightMargin>
          </HorizontalScroller>
        </Col>
      </Row>
      {errors && (
        <Row>
          <Col>
            <ErrorContainer errors={errors} />
          </Col>
        </Row>
      )}
    </>
  );
}

function ScenarioCard({
  sites,
  scenario,
  siteScenarios,
  scenarioFields,
  arrayDesignFields,
  storageDesignFields,
  projectScheduleFields,
  siteScenarioFields,
  customizeArrayDesignByScenario,
  customizeStorageDesignByScenario,
  customizeProjectScheduleByScenario,
  eventKey,
  locked,
  onChangeSiteScenario,
}) {
  const { dispatch, state } = useBBEContext();
  const onCellsChanged = cellsChangedHandler({
    collection: siteScenarios,
    callback: onChangeSiteScenario,
  });
  const onBid = bidHandler({
    collection: siteScenarios,
    callback: onChangeSiteScenario,
  });

  const scenarioEnergyTechnology = getScenarioEnergyTechnology(scenario, siteScenarios);
  const scenarioName = getScenarioName(scenario, siteScenarios);

  const [arrayErrorCount, setArrayErrorCount] = useState(0);
  const [storageErrorCount, setStorageErrorCount] = useState(0);
  const [projectErrorCount, setProjectErrorCount] = useState(0);
  const [siteScenarioErrorCount, setSiteScenarioErrorCount] = useState(0);
  const errorCount =
    arrayErrorCount + storageErrorCount + projectErrorCount + siteScenarioErrorCount;

  const uploadFile = useUploadFiles();
  const handleUploadArrayLayout = uploadHandler({
    uploadFile,
    onCellsChanged,
    fileProperty: 'arrayLayout',
  });
  const handleUploadStorageDesign = uploadHandler({
    uploadFile,
    onCellsChanged,
    fileProperty: 'storageDesign',
  });

  return (
    <ExpandCollapse
      title={`${Number(eventKey) + 1}) ${scenarioName}`}
      openByDefault={expandCollapseByTitleSelector(state, scenarioName)}
      errorCount={errorCount}
      onToggle={() =>
        dispatch({
          type: TOGGLE_EXPAND_COLLAPSE,
          payload: scenarioName,
        })
      }
    >
      <ReadOnlyContainer readOnly={locked}>
        <SeparationRow>
          <Col xs={6}>
            {scenario && <ScenarioGivenFields scenario={scenario} fields={scenarioFields} />}
          </Col>
        </SeparationRow>
        {isSolar(scenarioEnergyTechnology) && customizeArrayDesignByScenario && (
          <SeparationRow>
            <Col xs={12}>
              <CascadingSiteDataSheet
                title="Array Design Details"
                topLevelSites={sites}
                sites={siteScenarios}
                fields={arrayDesignFields}
                hideBid={true}
                onChangeSite={onChangeSiteScenario}
                onChangeSiteFiles={handleUploadArrayLayout}
                fileProperty="arrayLayout"
                showCustomize={false}
                setErrorCount={setArrayErrorCount}
                showActions={true}
                allowGivenFields={true}
              />
            </Col>
          </SeparationRow>
        )}
        {isStorage(scenarioEnergyTechnology) && customizeStorageDesignByScenario && (
          <SeparationRow>
            <Col xs={12}>
              <CascadingSiteDataSheet
                title="Storage Design Details"
                topLevelSites={sites}
                sites={siteScenarios}
                fields={storageDesignFields}
                hideBid={true}
                onChangeSite={onChangeSiteScenario}
                onChangeSiteFiles={handleUploadStorageDesign}
                fileProperty="storageDesign"
                showCustomize={false}
                setErrorCount={setStorageErrorCount}
                showActions={true}
                allowGivenFields={true}
              />
            </Col>
          </SeparationRow>
        )}
        {customizeProjectScheduleByScenario && (
          <SeparationRow>
            <Col xs={12}>
              <CascadingSiteDataSheet
                title="Project Schedule"
                topLevelSites={sites}
                sites={siteScenarios}
                fields={projectScheduleFields}
                hideBid={true}
                onChangeSite={onChangeSiteScenario}
                showCustomize={false}
                allowGivenFields={true}
                setErrorCount={setProjectErrorCount}
              />
            </Col>
          </SeparationRow>
        )}
        <SiteScenarioFields
          sites={sites}
          scenario={scenario}
          siteScenarios={siteScenarios}
          fields={siteScenarioFields}
          disableBid={false}
          onBid={onBid}
          onCellsChanged={onCellsChanged}
          setErrorCount={setSiteScenarioErrorCount}
        />
      </ReadOnlyContainer>
    </ExpandCollapse>
  );
}

export default ScenarioCard;
