import { useState } from 'react';
import _ from 'lodash';
import { useBBEDispatch } from '../../../core/store';
import { DEFAULT_EXPAND_COLLAPSE } from '../../../core/actions';

export const useExpandedSections = optIn => {
  const [defaultReceived, setDefaultReceived] = useState(false);
  const dispatch = useBBEDispatch();

  if (!defaultReceived && !_.isEmpty(optIn)) {
    dispatch({
      type: DEFAULT_EXPAND_COLLAPSE,
      payload: _.get(optIn, 'saved.view.expandedSections'),
    });
    setDefaultReceived(true);
  }
};
