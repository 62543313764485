import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import ErrorContainer from './Error/ErrorContainer';
import Loader from '../components/Loader';
import { useRFPQuery } from './RFP/hooks/query';
import useAxiosApi from '../core/hooks/axiosApi';
import useTokenSession from '../services/tokenSession';

// @Object
// optInId: number
// userId: number
export const SessionContext = React.createContext({});

const defaultRfpValues = {
  optInId: 8948,
  userId: 1271,
};

export const Session = props => {
  const [sessionData, setSessionData] = React.useState({
    optInId: 0,
    userId: 0,
    readOnly: false,
    empty: false,
  });
  const [isLoadingDevToken, setIsLoadingDevToken] = React.useState(false);
  const location = useLocation();
  const optInQueryParams = useRFPQuery();
  const axiosApi = useAxiosApi();

  const { isLoading, error, tokenData } = useTokenSession({ queryString: location.search });
  const { token } = optInQueryParams;

  if (
    tokenData &&
    tokenData.optInId &&
    tokenData.optInId !== sessionData.optInId &&
    tokenData.userId &&
    tokenData.userId !== sessionData.userId
  ) {
    setSessionData(tokenData);
  }
  /** Dev Token */
  const isDev =
    process.env.REACT_APP_ENV === 'development' || process.env.NODE_ENV === 'development';

  const shouldGenerateTokenInDev =
    isDev && process.env.REACT_APP_SHOULD_AUTO_GENERATE_TOKEN === 'true' && token == null;

  if (isDev && !shouldGenerateTokenInDev) {
    console.warn('Not auto-generating a token for development', {
      isDev,
      REACT_APP_SHOULD_AUTO_GENERATE_TOKEN:
        process.env.REACT_APP_SHOULD_AUTO_GENERATE_TOKEN === 'true',
      token,
      optInQueryParams,
    });
  } else if (shouldGenerateTokenInDev) {
    const execute = async () => {
      setIsLoadingDevToken(true);
      console.warn('Generating a token for development');
      const payload = { optInId: defaultRfpValues.optInId, userId: defaultRfpValues.userId };
      await getDevToken(axiosApi, payload);
      setSessionData(payload);
      setIsLoadingDevToken(false);
    };
    if (!isLoadingDevToken) {
      execute();
    }
  }

  /** End Dev */
  if (isLoading || isLoadingDevToken) {
    return <Loader />;
  }

  if (token == null) {
    return <Redirect to="/not-authorized" from="/" />;
  }

  if (error) {
    const { response = {} } = error;
    const { data = {} } = response;
    return <ErrorContainer errors={[data.message || error.message]} />;
  }
  const { children } = props;

  return (
    <SessionContext.Provider value={{ sessionData, setSessionData }}>
      {children}
    </SessionContext.Provider>
  );
};

const getDevToken = async (axiosApi, payload) => {
  const response = await axiosApi.post('/auth', payload, {
    headers: { Authorization: 'Bearer TEST_TOKEN' },
  });
  // place token on url so InitSession receives and handles it appropriately
  console.log('GENERATED TOKEN', response.data.token);

  window.history.replaceState(null, '', `/?token=${response.data.token}&addQueryPrefix=false`);
  window.location.reload();
};
