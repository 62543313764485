import _ from 'lodash';
import { useState } from 'react';
import { mergeSiteObjects, mergeChanges } from '../utils/collection';
import { isSolar, isStorage } from '../containers/RFP/components/helpers/scenario';
import { useAnyChangesSinceSave } from '../core/hooks/anyChanges';
import {
  RELATED_SITE_RFP_FIELD,
  RELATED_SCENARIO_FIELD,
  ENERGY_TECHNOLOGY_FIELD,
} from '../containers/RFP/components/transformers/specialFields';

const matchesTechnology = (energyTechnology, siteScenarioEnergyTechnology) =>
  !energyTechnology ||
  (isSolar(energyTechnology) && isSolar(siteScenarioEnergyTechnology)) ||
  (isStorage(energyTechnology) && isStorage(siteScenarioEnergyTechnology));

export const useSiteScenariosState = (defaultSiteScenarios, defaultScenarios) => {
  const [__, onChangesMade] = useAnyChangesSinceSave(); // eslint-disable-line no-unused-vars

  const indexedScenarios = _.keyBy(defaultScenarios, 'id');
  const getEnergyTechnology = siteScenario =>
    _.get(indexedScenarios, [
      _.get(siteScenario, `${RELATED_SCENARIO_FIELD}.value`),
      ENERGY_TECHNOLOGY_FIELD,
      'value',
    ]);

  const transformedDefaultSiteScenarios = _.map(defaultSiteScenarios, siteScenario => {
    const scenarioEnergyTechnology = getEnergyTechnology(siteScenario);
    const arrayDesignBid = isSolar(scenarioEnergyTechnology)
      ? { arrayDesignBid: { value: true } }
      : {};
    const storageDesignBid = isStorage(scenarioEnergyTechnology)
      ? { storageDesignBid: { value: true } }
      : {};
    return {
      ...siteScenario,
      ...arrayDesignBid,
      ...storageDesignBid,
      projectScheduleBid: {
        value: true,
      },
      bid: _.get(siteScenario, 'bid', {
        value: true,
      }),
    };
  });
  const [siteScenarios, onSetSiteScenarios] = useState(transformedDefaultSiteScenarios);

  // Use setSiteScenarios and not onSetSiteScenarios
  const setSiteScenarios = siteScenarios => {
    onSetSiteScenarios(siteScenarios);

    // NOTE - Signals unsaved changes to the central State Provider.
    onChangesMade();
  };

  // NOTE - Not in love with this defaulting, but do not have another great way
  const [defaultReceived, setDefaultReceived] = useState(false);
  if (!_.isEmpty(transformedDefaultSiteScenarios) && !defaultReceived) {
    setDefaultReceived(true);
    setSiteScenarios(transformedDefaultSiteScenarios);
  }

  const setSiteScenarioOnCellChange = siteScenario => {
    const updatedSiteScenarios = mergeSiteObjects(siteScenarios, siteScenario, 'id');
    setSiteScenarios(updatedSiteScenarios);
  };

  const cascadeChangesToAllSiteScenarios = (changes, energyTechnology) => {
    const uniqueSiteIds = _.uniqBy(_.map(changes, change => _.get(change, 'cell.recordId')));

    return setSiteScenarios(
      _.map(siteScenarios, siteScenario => {
        const relatedSiteId = _.get(siteScenario, `${RELATED_SITE_RFP_FIELD}.value`);
        const scenarioEnergyTechnology = getEnergyTechnology(siteScenario);
        if (
          _.includes(uniqueSiteIds, relatedSiteId) &&
          matchesTechnology(energyTechnology, scenarioEnergyTechnology)
        ) {
          const relatedChanges = _.filter(
            changes,
            change => change.cell.recordId === relatedSiteId,
          );
          const mergedChanges = mergeChanges(siteScenario, relatedChanges);
          return mergedChanges;
        } else {
          return siteScenario;
        }
      }),
    );
  };

  const cascadeBidToAllSiteScenarios = ([{ bidKey = 'bid', energyTechnology, site, updated }]) =>
    setSiteScenarios(
      _.map(siteScenarios, siteScenario => {
        const scenarioEnergyTechnology = getEnergyTechnology(siteScenario);
        if (
          _.get(siteScenario, `${RELATED_SITE_RFP_FIELD}.value`) === _.get(site, 'id') &&
          matchesTechnology(energyTechnology, scenarioEnergyTechnology)
        ) {
          return _.merge({}, siteScenario, {
            [bidKey]: {
              value: _.get(updated, `${bidKey}.value`),
            },
          });
        } else {
          return siteScenario;
        }
      }),
    );

  return [
    siteScenarios,
    setSiteScenarioOnCellChange,
    cascadeChangesToAllSiteScenarios,
    cascadeBidToAllSiteScenarios,
  ];
};
