import { useState } from 'react';
import _ from 'lodash';

export const useUpdateQueue = (defaultValue = []) => {
  const [updateQueue, setUpdateQueue] = useState(defaultValue);

  const appendToUpdateQueue = value => setUpdateQueue(_.concat(updateQueue, value));

  const markItemsUpdated = (values, identifier = 'id') => {
    const asArray = _.castArray(values);
    setUpdateQueue(
      _.reject(updateQueue, item => _.find(asArray, { [identifier]: _.get(item, identifier) })),
    );
  };

  return [updateQueue, appendToUpdateQueue, markItemsUpdated];
};
