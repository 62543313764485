import { useAPI as defaultUseAPI } from 'react-api-hooks';
import qs from 'qs';
import { useRFPQuery } from '../../containers/RFP/hooks/query';

export function ensureUrlToken(url, token) {
  if (token) {
    return [url, !~url.indexOf('?') ? `?` : '&', qs.stringify({ token })].join('');
  }
  return url;
}

export default function useAPI(plainUrl, config, initialFetch) {
  const { token } = useRFPQuery();

  return defaultUseAPI(
    ensureUrlToken(plainUrl, token),
    {
      withCredentials: true,
      ...config,
    },
    initialFetch,
  );
}
