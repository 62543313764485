import _ from 'lodash';
import {
  fieldsToHeader,
  fieldsAndRecordToRow,
  removeEmptyColumns,
  matchHeaderToColumns,
} from './common';
import { PROPERTY_NAME_FIELD } from './specialFields';

export const valueRenderer = cell => _.get(cell, 'value');

export function toDataSheetRows({ siteRfps, fields }) {
  const contentPreEmptyColumns = _.chain(siteRfps)
    .compact()
    .sortBy(_.property(`${PROPERTY_NAME_FIELD}.value`))
    .map(record =>
      fieldsAndRecordToRow({
        fields,
        record,
      }),
    )
    .value();
  const content = removeEmptyColumns(contentPreEmptyColumns);
  return _.concat(
    [
      // HEADER
      matchHeaderToColumns({
        header: fieldsToHeader({ fields }),
        content,
      }),
    ],
    // CONTENT
    content,
  );
}
