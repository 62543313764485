import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .rfpDataSheet {
    width: 100%;
    td {
      height: auto !important;
      background-clip: padding-box !important;
      margin: -1px;
    }
  }
  
  .data-grid-container .data-grid .cell > input {
    height: 100% !important;
    width: 100% !important;
  }

  .data-grid-container .data-grid .cell {
    vertical-align: middle;
  }
  
  .card-header {
    cursor: pointer;
  }
  
  .burger .burger-lines,
  .burger .burger-lines:after,
  .burger .burger-lines:before { background-color: #CC4893; }
  
  .popover {
    margin-right: -5px;
    
    .arrow {
      display: none;
    }
  }
`;
