import _ from 'lodash';
import { memoize } from './cache';
import { leadingZeroes } from './number';

export const alwaysParentheses = memoize(value => {
  const trimmed = _.trim(value);
  if (/^\(.*\)$/.test(value)) {
    return trimmed;
  } else if (/^\(/.test(value)) {
    return `${trimmed})`;
  } else if (/\)$/.test(value)) {
    return `(${trimmed}`;
  } else {
    return `(${trimmed})`;
  }
});

export const withoutSlashes = name => {
  const index = name.search(/\/(?!.*\/)/g);
  return ~index ? name.substr(index + 1) : name;
};

export const withoutTimestamp = name => {
  const t = name.match(/([0-9]*)-/);
  if (t) {
    const index = name.search(/([0-9]*)-/);

    return name.substr(index + t[0].length);
  }

  return name;
};

export const withoutQuery = name => {
  const index = name.search(/\?/g);
  return ~index ? name.substr(0, index) : name;
};

export function getUrlPath(url) {
  return withoutQuery(withoutTimestamp(withoutSlashes(url)));
}

export const dateFormat = timestamp => {
  const date = new Date(timestamp);
  return [
    leadingZeroes(date.getUTCMonth() + 1, 2),
    leadingZeroes(date.getUTCDate(), 2),
    leadingZeroes(date.getUTCFullYear(), 4),
  ].join('/');
};
