import { recordstoCSV, transformRecordFields } from './common';
import { getRelatedScenarioId, getRelatedSiteRfpId } from './siteScenario';

export const toCSV = ({
  fields,
  siteRfps,
  scenarios,
  siteScenarios,
  confirmations,
  questions,
  bfQuestions,
}) => {
  // Map siteScenarios to include all fields of related(Site/Scenario)
  const bidSiteScenarios = siteScenarios.map(siteScenario => {
    // Find relatedSite
    const siteRfp = siteRfps.find(({ id }) => id === getRelatedSiteRfpId(siteScenario)) || {};
    // Find relatedScenario
    const scenario = scenarios.find(({ id }) => id === getRelatedScenarioId(siteScenario)) || {};
    // Apply fields mappings and combine objects
    return {
      ...transformRecordFields(siteRfp, fields.sites),
      ...transformRecordFields(scenario, fields.scenarios),
      ...transformRecordFields(siteScenario, [
        ...fields.siteScenarios.arrayDesign,
        ...fields.siteScenarios.storageDesign,
        ...fields.siteScenarios.projectSchedule,
        ...fields.siteScenarios.bidSubmissionScenario,
      ]),
    };
  });

  return [
    recordstoCSV(bidSiteScenarios),
    '\nConfirmations',
    recordstoCSV(confirmations, [['confirmationText', 'Confirmation Text'], 'confirmation']),
    '\nQuestions',
    recordstoCSV(questions, ['question', 'answer']),
    '\nClarifications',
    recordstoCSV(bfQuestions, ['question', ['bbeFile', 'Attachment'], 'answer']),
  ].join('\n');
};
