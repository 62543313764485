import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import BurgerMenu from '../../../components/common/BurgerMenu';
import { ButtonLink } from '../../../components/common/BlackBearEnergy';
import { isDevelopment } from '../../../utils/enviroment';

const SecondaryUl = styled.ul`
  padding: 10px 25px;
`;

function SecondaryControls({ locked = false, onDelete, onSaveHistory, onExport }) {
  return (
    <BurgerMenu>
      {({ hide }) => {
        return (
          <SecondaryUl>
            {!locked && isDevelopment() && (
              <li>
                <ButtonLink variant="link" onClick={_.flow(onDelete, hide)}>
                  Clear Saved State
                </ButtonLink>
              </li>
            )}
            {!locked && (
              <li>
                <ButtonLink variant="link" onClick={_.flow(onSaveHistory, hide)}>
                  Save History
                </ButtonLink>
              </li>
            )}
            <li>
              <ButtonLink variant="link" onClick={onExport}>
                Export To CSV
              </ButtonLink>
            </li>
          </SecondaryUl>
        );
      }}
    </BurgerMenu>
  );
}

export default SecondaryControls;
