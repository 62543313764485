import _ from 'lodash';
import { withProps } from 'recompose';
import {
  fieldsToHeader,
  fieldsAndRecordToRow,
  removeEmptyColumns,
  matchHeaderToColumns,
  disableRowsOnPredicate,
} from './common';
import { assignGivenAndEditableToRow } from './editing';
import { getPropertyName, PROPERTY_NAME_FIELD, RELATED_SITE_RFP_FIELD } from './specialFields';
import BidRenderer from '../renderers/BidRenderer';
import HeaderRenderer from '../renderers/HeaderRenderer';
import EmptyRenderer from '../renderers/EmptyRenderer';
import DefaultRenderer from '../renderers/DefaultRenderer';

export const PROPERTY_NAME_COLUMN_WIDTH = 320;
export const BID_COLUMN_WIDTH = 160;
export const DATA_COLUMN_WIDTH = 220;

export const PROPERTY_NAME_COLUMN_FIELD_NAME = 'Property Name (OBSF)';
export const BID_COLUMN_FIELD_NAME = 'Bid';
export const ACTIONS_COLUMN_FIELD_NAME = 'Actions';

export const valueRenderer = cell => _.get(cell, 'value');
export const isEmptyColumn = item =>
  !_.get(item, 'userEdited') &&
  !_.get(item, 'value') &&
  _.get(item, 'fieldName') !== BID_COLUMN_FIELD_NAME;
export const isNoBidRow = row => !_.get(_.last(row), 'siteScenario.bid');

// TODO - We may be able to refactor this to be a little more modular, for now we'll focus on requirement stability
function givenFieldsToTheLeft(content) {
  if (_.size(content) > 1) {
    const header = _.first(content);
    const body = _.tail(content);

    const customFieldsByRelatedTypeAndOrder = _.chain(body)
      .first()
      .filter(_.property('custom'))
      .groupBy('relatedTypeAndOrder')
      .value();

    const fieldNameToIndex = _.chain(body)
      .first()
      .reject(_.property('custom'))
      .sortBy(item => {
        if (item.userEdited || item.fieldName === BID_COLUMN_FIELD_NAME) {
          return 1;
        } else {
          return 0;
        }
      })
      .reduce((acc, v, idx) => {
        // NOTE - The multiplication creates padding so we can put custom fields to the right.
        const expandedIndex = idx * 10;
        acc[v.fieldName] = expandedIndex;
        // NOTE - Don't like doing this again here, but not sure if we have a better choice.
        const customFields = customFieldsByRelatedTypeAndOrder[v.fieldId];
        if (customFields) {
          _.each(customFields, ({ fieldName }, subIdx) => {
            acc[fieldName] = expandedIndex + subIdx + 1;
          });
        }
        return acc;
      }, {})
      .value();

    const sortedBody = _.map(body, row =>
      _.sortBy(row, ({ fieldName }) => fieldNameToIndex[fieldName]),
    );
    const sortedHeader = _.sortBy(header, ({ fieldName }) => fieldNameToIndex[fieldName]);

    return _.concat([sortedHeader], sortedBody);
  } else {
    return content;
  }
}

function getRelatedSite(sites, siteScenario) {
  const relatedSiteId = _.get(siteScenario, [RELATED_SITE_RFP_FIELD, 'value']);
  if (relatedSiteId) {
    return _.find(sites, { id: relatedSiteId });
  }
}

export function toDataSheetRows({
  sites,
  scenario,
  siteScenarios,
  fields,
  fieldThresholds,
  bidKey = 'bid',
  horizontalScroll = false,
  disableBid = false,
  emptyColumnPredicate = isEmptyColumn,
  onBid,
}) {
  const customFields = _.get(scenario, 'customFields');
  const allFields = _.chain(fields)
    .concat(customFields)
    .compact()
    .value();
  const content = disableRowsOnPredicate(
    removeEmptyColumns(
      _.chain(siteScenarios)
        .compact()
        .sortBy(_.property(`${PROPERTY_NAME_FIELD}.value`))
        .map(record => {
          const { id } = record;
          return _.concat(
            {
              fieldName: PROPERTY_NAME_COLUMN_FIELD_NAME,
              // NOTE - We prefer the value right from the site name in case it is updated via Quickbase.
              value: getPropertyName(getRelatedSite(sites, record) || record),
              readOnly: true,
              width: PROPERTY_NAME_COLUMN_WIDTH,
              valueViewer: DefaultRenderer,
            },
            fieldsAndRecordToRow({
              fields: allFields,
              record,
              fieldThresholds,
              width: horizontalScroll ? DATA_COLUMN_WIDTH : undefined,
            }),
            {
              fieldName: BID_COLUMN_FIELD_NAME,
              readOnly: true,
              siteScenario: {
                id,
                bid: !!_.get(record, `${bidKey}.value`),
                onBid: _.partial(onBid, id),
              },
              valueViewer: withProps({ disabled: disableBid })(BidRenderer),
              width: BID_COLUMN_WIDTH,
            },
          );
        })
        .map(assignGivenAndEditableToRow)
        .value(),
      emptyColumnPredicate,
    ),
    isNoBidRow,
  );

  return givenFieldsToTheLeft(
    _.concat(
      [
        // HEADER
        matchHeaderToColumns({
          header: _.concat(
            {
              value: PROPERTY_NAME_COLUMN_FIELD_NAME,
              fieldName: PROPERTY_NAME_COLUMN_FIELD_NAME,
              readOnly: true,
              width: BID_COLUMN_WIDTH,
              valueViewer: HeaderRenderer,
            },
            fieldsToHeader({
              fields: allFields,
              width: horizontalScroll ? DATA_COLUMN_WIDTH : undefined,
            }),
            {
              value: BID_COLUMN_FIELD_NAME,
              fieldName: BID_COLUMN_FIELD_NAME,
              readOnly: true,
              width: BID_COLUMN_WIDTH,
              valueViewer: EmptyRenderer,
            },
          ),
          content,
        }),
      ],
      // CONTENT
      content,
    ),
  );
}

export function groupByScenario({ siteScenarios }) {
  return _.groupBy(siteScenarios, _.property('relatedScenario.value'));
}

export function distributeColumns({ width, data }) {
  const firstRow = _.first(data);
  const allColumnWidths = _.sumBy(firstRow, 'width');
  if (_.parseInt(allColumnWidths) < _.parseInt(width)) {
    const distributedWidth = Math.floor((width - BID_COLUMN_WIDTH) / (firstRow.length - 1));
    return _.map(data, row =>
      _.map(row, col => {
        if (col.fieldName === BID_COLUMN_FIELD_NAME) {
          col.width = BID_COLUMN_WIDTH;
        } else {
          col.width = distributedWidth;
        }
        return col;
      }),
    );
  }
  return data;
}

export const getRelatedSiteRfpId = siteScenario => _.get(siteScenario, 'relatedRfpSite.value');

export const getRelatedScenarioId = siteScenario => _.get(siteScenario, 'relatedScenario.value');
